import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { connectRouter } from 'connected-react-router'
import user from './user';
import modal from './modal';
import profile from './profile';
import project from './project';

export default (history) => combineReducers({
  router: connectRouter(history),
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user,
  modal,
  profile,
  project,
})

import * as types from '../constants/ActionTypes';
import { profile as initialState } from '../initialState';
import { RequestState } from '../constants/enums';

export default function profile(state = initialState, action) {
  switch (action.type) {
    case types.PROFILE_INITIALIZE_PROFILE:
      return {
        ...state,
        fields: Object.assign({}, state.fields, action.fields),
        isLoaded: true,
        requestState: RequestState.READY,
      };
    case types.PROFILE_SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.fieldName]: action.value,
        }
      };
    case types.PROFILE_SET_REQUEST_STATE:
      return {
        ...state,
        requestState: action.requestState,
        error: action.error === undefined ? state.error : action.error
      };
    default:
      return state;
  }
}

import React, {Component} from "react";
import { FaTwitter, FaLinkedin, FaTimes, FaCoffee } from 'react-icons/fa';

import {CLICK_CONTACT_INFO, Sections} from '../../constants/Analytics';
import Button from '../../components/Button';
import Chip from '../../components/Chip';
import './PartnerModal.scss';
import './Modal.scss';

const OFFICE_HOURS_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSfvyi_B8sXOEI0vCW9jgs0rSqEN3PG9JFhwEa1maHQHTFJ1-g/viewform';

export class PartnerModal extends Component {


  renderContent() {
    const { partner } = this.props;

    const trackClick = (url) => (e) => {
      window.analytics.track(CLICK_CONTACT_INFO, {
        section: Sections.Venture,
        item: partner,
        link: url
      });
    }

    const focusAreas = partner.focusAreas
      .split(',')
      .map(s => s.trim())
    return (
      <React.Fragment>
        <div className="header">
          <i style={{backgroundImage: `url(${partner.linkToHeadshot})`}} />
          <div>
            <h2>{partner.fullName} </h2>
            <h3 className="light">{partner.title.trim() + ','} {partner.firm}</h3>
          </div>
        </div>
        { partner.messageToMembers && (
          <p className="welcome-message">{ partner.messageToMembers }</p>
        )}
        <div className="content-area">
          <div className="primary-area">
            { partner.endorsements?.length > 0 && (
            <React.Fragment>
              <h3>Testimonials From Fellows</h3>
              <ul className="endorsements">
                { partner.endorsements.map((e, eIdx) => (
                  <li className="endorsement" key={eIdx}>
                    <div class="endorser">
                      <h4>{e.endorserFullName}, {e.endorserCohort}</h4>
                      <p className="small light">{e.endorserCurrent}</p>
                    </div>
                    <p className="small"> {e.endorsement}</p>
                  </li>
                ))}
              </ul>
            </React.Fragment>
            )}
            <h3>About {partner.firstName}</h3>
            <p>{ partner.bio }</p>
          </div>
          <div className="secondary-area">
            <h3>Focus areas</h3>
            { focusAreas && (
              <ul>
                {focusAreas.map((fa, faIdx) => (
                  <Chip size="large" key={faIdx}>{fa}</Chip>
                ))}
              </ul>
            )}
            <h3>Sign up for office hours</h3>
            <div className="button-group">
              <Button
                target="_blank"
                onClick={trackClick(OFFICE_HOURS_FORM)}
                href={OFFICE_HOURS_FORM}
                color="green-line small">
                  <FaCoffee />
                  <span className="social-text">Request Intro</span>
              </Button>
            </div>
            <h3>Across the Net</h3>
            <div className="button-group">
              {partner.linkedinProfileUrl && (
                <Button
                  target="_blank"
                  onClick={trackClick(partner.linkedinProfileUrl)}
                  href={partner.linkedinProfileUrl}
                  color="green-line small">
                    <FaLinkedin />
                    <span className="social-text">LinkedIn</span>
                </Button>
              )}
              {partner.twitterUsername && (
                <Button
                  target="_blank"
                  onClick={trackClick(partner.twitterUsername)}
                  href={`https://twitter.com/${partner.twitterUsername}`}
                  color="green-line small">
                    <FaTwitter />
                    <span className="social-text">Twitter</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { partner, startClosing } = this.props;
    return (
      <div className="Modal">
        <div className="modal-scrim" onClick={startClosing}></div>
        <div className="modal-window">
          <div className="top-bar">
            <FaTimes className="close-button" onClick={startClosing} />
          </div>
          <div className="modal-content">
            { partner && this.renderContent() }
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerModal;

import * as types from '../constants/ActionTypes';
import { project as initialState } from '../initialState';

export default function project(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_INITIALIZE_PROJECT:
      return {
        ...state,
        fields: {
          ...action.fields,
        },
        error: initialState.error,
        isLoaded: true,
      };
    case types.PROJECT_SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.fieldName]: action.value,
        }
      };
    case types.PROJECT_SET_REQUEST_STATE:
      return {
        ...state,
        requestState: action.requestState,
        error: (action.error === undefined
          ? state.error
          : action.error === null
            ? initialState.error
            : action.error)
      };
    default:
      return state;
  }
}

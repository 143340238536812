import React, { Component } from 'react';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as FontAwesome from 'react-icons/fa';
import {CLICK_ENTRY, Sections} from '../constants/Analytics';
import IconCard from './IconCard';
import {TABLES} from '../shared/schema';

const enhance = compose(
  firebaseConnect((props) => [
    { path: TABLES.RESOURCES.firebaseRef }
  ]),
  connect(({ firebase }) => ({
    resources: firebase.data[TABLES.RESOURCES.firebaseRef],
  }))
);

const _conformIconText = function(iconText) {
  // foo-bar-baz > Foo-bar-baz
  let ict = iconText.charAt(0).toUpperCase() + iconText.slice(1);
  // Foo-bar-baz > FooBarBaz
  ict = ict.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
  return ict;
}

class ResourcesList extends Component {
  render() {
    const resources = Object.values(this.props.resources || {});

    if (!isLoaded(resources)) {
      return (
        <div>
          <div className="placeholder">
            <span className="Spinner dark"/>
          </div>
        </div>
      );
    }

    const resourcesList = resources && resources.map((resource, idx) => (
      <IconCard key={idx}
                Icon={FontAwesome[_conformIconText(resource.icon)] || FontAwesome.FaBook}
                title={resource.title}
                href={resource.link}
                onClick={() => {
                  window.analytics.track(CLICK_ENTRY, {
                    section: Sections.RESOURCES,
                    item: resource,
                  });
                }}
                imageUrl={resource.coverImage} />
    ));

    return (
      <div className="cardlist">
        {resourcesList}
      </div>
    );
  }
}

export default enhance(ResourcesList);

import React, { Component } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { compose } from 'redux';
import Dropzone from 'react-dropzone';
import { withCurrentMember } from '../../utils';

import './index.scss';

const enhance = compose(
  withFirebase,
  withCurrentMember,
);

function getDownloadURLFromUploadTaskSnapshot(uploadTaskSnapshot) {
  // Handle different downloadURL patterns (Firebase JS SDK v5.*.* vs v4.*.*)
  if (
    uploadTaskSnapshot.ref &&
    typeof uploadTaskSnapshot.ref.getDownloadURL === 'function'
  ) {
    // Get downloadURL and attach to response
    return uploadTaskSnapshot.ref.getDownloadURL()
  }
  // Only attach downloadURL if downloadURLs is defined (not defined in v5.*.*)
  return Promise.resolve(
    uploadTaskSnapshot.downloadURLs && uploadTaskSnapshot.downloadURLs[0]
  )
}

class ProfilePictureEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      newDownloadUrl: null,
      previewObjectUrl: null,
    };
  }

  render() {
    const { airtableId, firebase, value } = this.props;
    const { uploading, newDownloadUrl, previewObjectUrl } = this.state;

    const onFilesDrop = (files) => {
      const updateProfile = firebase.functions().httpsCallable('updateProfile-updateProfile');
      this.setState({
        uploading: true,
        previewObjectUrl: URL.createObjectURL(files[0]),
      });
      return firebase
        .uploadFiles(airtableId, files)
        .then(snapshots => {
          const uploadTaskSnapshot = snapshots[0].uploadTaskSnapshot;

          // Set profile picture URL
          getDownloadURLFromUploadTaskSnapshot(uploadTaskSnapshot).then(downloadUrl => {
            updateProfile({
              airtableId: airtableId,
              fields: {
                linkToHeadshot: downloadUrl,
              }
            }).then((result) => {
              console.log(result);
              this.setState({uploading: false, newDownloadUrl: downloadUrl});
              setTimeout(() => {this.setState({previewObjectUrl: null})}, 4000);
            }).catch((error) => {
              console.error(error);
              this.setState({uploading: false});
              setTimeout(() => {this.setState({previewObjectUrl: null})}, 4000);
            });
          });

          // Delete the other images
          uploadTaskSnapshot.ref.parent.listAll().then(res => {
            res.items.forEach(itemRef => {
              if (itemRef.location.path !== uploadTaskSnapshot.ref.location.path) {
                itemRef.delete();
              }
            });
          });
        });
    }

    // if (!memberInfo) {
    //   return (
    //     <div className="ProfilePictureEditor">
    //       <div className="placeholder">
    //         <span className="Spinner dark" />
    //       </div>
    //     </div>
    //   );
    // }

    const isStillUploading = uploading || (newDownloadUrl && newDownloadUrl !== value);
    const missingHeadshot = !value;

    return (
      <div className={`ProfilePictureEditor ${isStillUploading ? 'uploading' : ''} ${missingHeadshot ? 'empty' : ''}`}>
        <Dropzone onDrop={onFilesDrop}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()} className="inner">
              <i className="real-image" style={{backgroundImage: `url(${value})`}} />
              {previewObjectUrl && <i className="preview-image" style={{backgroundImage: `url(${previewObjectUrl})`}} />}
              <input {...getInputProps()} disabled={uploading}/>
              <div className="scrim">
                <label>{
                  isStillUploading
                  ? 'Uploading...'
                  : isDragActive
                  ? 'Drop to Upload'
                  : value
                  ? 'Change'
                  : 'Upload'}</label>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

}

export default enhance(ProfilePictureEditor);






import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { firebaseConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import NewsletterList from "../components/NewsletterList";
import MemberList from "../components/MemberList";
import TeamList from "../components/TeamList";
import ResourcesList from "../components/ResourcesList";
import EventList from "../components/EventList";
import VentureFirmList from "../components/VentureFirmList";
import Button from "../components/Button";
import ModalManager from "../components/modals/ModalManager";
import { ModalType } from "../constants/enums";
import * as ModalActions from "../actions/ModalActions";
import { CLICK_CTA, Sections } from "../constants/Analytics";
import { TABLES } from "../shared/schema";

import "./MemberHome.scss";

const enhance = compose(
  withRouter,
  firebaseConnect((props) => {
    const { match } = props;
    let paths = [{ path: TABLES.MEMBERS.firebaseRef }];

    if (match && match.params && match.params.partnerSlug) {
      paths.push({
        path: TABLES.VENTURE_PARTNERS.firebaseRef,
        queryParams: [
          "orderByChild=slug",
          `equalTo=${match.params.partnerSlug}`,
        ],
        storeAs: "matchedPartner",
      });
    }

    if (match && match.params && match.params.memberSlug) {
      paths.push({
        path: TABLES.MEMBERS.firebaseRef,
        queryParams: [
          "orderByChild=slug",
          `equalTo=${match.params.memberSlug}`,
        ],
        storeAs: "matchedMember",
      });
    }

    return paths;
  }),
  connect(
    ({ firebase }) => ({
      auth: firebase.auth,
      members: firebase.data[TABLES.MEMBERS.firebaseRef],
      matchedPartner: firebase.data.matchedPartner,
      matchedMember: firebase.data.matchedMember,
    }),
    (dispatch, ownProps) => ({
      setModal: (modalType, modalData) => {
        if (modalType && modalData) {
          dispatch(
            ModalActions.openModal(modalType, modalData, { returnTo: "/home" })
          );
        } else {
          dispatch(ModalActions.closeModal());
        }
      },
    })
  )
);

class MemberHome extends Component {
  constructor(props) {
    super();
    this.state = {
      modalType: null,
      modalData: null,
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    window.analytics.page("MemberHome");
    if (location.pathname === "/") {
      history.replace("/home");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.matchedPartner !== prevState.matchedPartner &&
      nextProps.location.pathname.startsWith("/partner")
    ) {
      if (nextProps.matchedPartner === null) {
        return {
          modalType: null,
          modalData: null,
        };
      }
      const partner = Object.values(nextProps.matchedPartner)[0];
      return {
        modalType: ModalType.PARTNER_MODAL,
        modalData: { partner },
      };
    } else if (
      nextProps.matchedMember !== prevState.matchedMember &&
      nextProps.location.pathname.startsWith("/member")
    ) {
      if (nextProps.matchedMember === null) {
        return {
          modalType: null,
          modalData: null,
        };
      }
      const member = Object.values(nextProps.matchedMember)[0];
      return {
        modalType: ModalType.MEMBER_MODAL,
        modalData: { member },
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { setModal } = this.props;
    const { modalType, modalData } = this.state;
    if (prevState.modalData !== modalData) {
      setModal(modalType, modalData);
    }
  }

  trackCTAClick(section) {
    return (e) => {
      window.analytics.track(CLICK_CTA, {
        section: section,
        buttonText: e.nativeEvent.target.textContent,
      });
    };
  }

  render() {
    const { matchedPartner, matchedMember, match } = this.props;
    let content;
    if (
      (!isLoaded(matchedPartner) && match.params.partnerSlug) ||
      (!isLoaded(matchedMember) && match.params.memberSlug)
    ) {
      content = (
        <div className="placeholder">
          <span className="Spinner dark" />
        </div>
      );
    } else {
      content = (
        <React.Fragment>
          <section id="members">{this.renderMembers()}</section>
          <section id="events">{this.renderEvents()}</section>
          <section id="newsletters">{this.renderNewsletters()}</section>
          <section id="resources">{this.renderResources()}</section>
          <section id="venture">{this.renderVenture()}</section>
          <section id="team">{this.renderTeam()}</section>
        </React.Fragment>
      );
    }

    return (
      <div className="MemberHome">
        <Navbar subNav="home" />
        {content}
        <ModalManager />
        <Footer />
      </div>
    );
  }

  renderVenture() {
    return (
      <div className="content">
        <h2>Venture Partners</h2>
        <div className="description">
          <p>
            Our venture partners work closely with the Interact team, and have
            backed dozens of Interact founders. Read more about them below.
          </p>
        </div>
        <VentureFirmList />
      </div>
    );
  }

  renderEvents() {
    return (
      <div className="content">
        <h2>Events</h2>
        <div className="description">
          <p>
            Upcoming Interact-hosted events, partner events, and community
            meetups in your city.
          </p>
          <div className="call-to-action">
            <Button
              onClick={this.trackCTAClick(Sections.EVENTS)}
              href="/url?u=https://airtable.com/shrcz4Paqo0v1oaGZ?prefill_Email+Address=[[member_email]]"
              color="green-line"
            >
              Host Event
            </Button>
          </div>
        </div>
        <EventList />
      </div>
    );
  }

  renderNewsletters() {
    return (
      <div className="content">
        <h2>Newsletters</h2>
        <div className="description">
          <p>
            Every term, we release a newsletter to share Interact and personal
            updates with the community. There's no update that's too big or
            small: we want to hear about your recent move or job change, your
            new art installation, an article you wrote, fundraising milestones,
            a project you've launched, and more.
          </p>
          <div className="call-to-action">
            <Button
              target="_blank"
              onClick={this.trackCTAClick(Sections.NEWSLETTERS)}
              href="https://go.joininteract.com/communityupdate"
              color="green-line"
            >
              Share Update
            </Button>
          </div>
        </div>
        <NewsletterList />
      </div>
    );
  }

  renderResources() {
    return (
      <div className="content">
        <h2>Resources</h2>
        <div className="description">
          <p>
            Browse helpful resources, documents, and templates put together by
            the Interact team. Request a new resource if you need something
            else!
          </p>
          <div className="call-to-action">
            <Button
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.trackCTAClick(Sections.RESOURCES)}
              href="mailto:team@joininteract.com"
              color="green-line"
            >
              Request New
            </Button>
          </div>
        </div>
        <ResourcesList />
      </div>
    );
  }

  renderMembers() {
    return (
      <div className="content">
        <h2>Members</h2>
        <div className="description">
          <p>
            Search for members who are in your city with the Map view. Add your
            location in your profile to show up on the map! You can also search
            for members who are in your cohort, or interested in similar topics
            with the filter.
          </p>
          <div className="call-to-action">
            <Button
              to={"/profile"}
              onClick={this.trackCTAClick(Sections.MEMBERS)}
              color="green-line"
            >
              Update Profile
            </Button>
          </div>
        </div>
        <MemberList />
      </div>
    );
  }

  renderTeam() {
    return (
      <div className="content">
        <h2>Team</h2>
        <div className="description">
          <p>
            Interact is made possible by awesome members of our community. If
            you're looking for the community lead in your city or have an idea
            to share with the planning team, don't hesitate to reach out to us.
          </p>
          <div className="call-to-action">
            <a
              href="mailto:team@joininteract.com"
              onClick={this.trackCTAClick(Sections.TEAM)}
              className="Button green-line"
            >
              Email Team
            </a>
          </div>
        </div>
        <TeamList />
      </div>
    );
  }
}

export default enhance(MemberHome);

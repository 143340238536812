import React from 'react';
import { FaRegSquare, FaCheckSquare } from 'react-icons/fa';

class CheckboxField extends React.Component {
  render() {
    const {tableSchema, title, description, id, label, isChecked, error, onChange} = this.props;
    const hasError = error?.field === id;
    return (
      <div className={`form-control checkbox ${hasError ? 'has-error' : ''}`}>
        {label && <label>{label} {tableSchema.fieldSchemas[id]?.required ? <span className="required" />: ''}</label>}
        <label htmlFor={id}>
          { isChecked ? <FaCheckSquare /> : <FaRegSquare /> }
          <span className="text">
            <span className="title">{title}</span>
            <span className="description">{description}</span>
          </span>
        </label>
        <input
          type="checkbox"
          onChange={(e) => onChange(id, e.target.checked)}
          name={id}
          id={id}
          checked={isChecked}
        />
        {hasError && (
          <div className="error">{error.error}</div>
        )}
      </div>
    );
  }
}

export default CheckboxField;

import React, { Component } from "react";
import { firebaseConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { FaCalendarAlt, FaChevronDown } from "react-icons/fa";
import classNames from "classnames";
import { EXPAND_SECTION, CLICK_ENTRY, Sections } from "../constants/Analytics";
import IconCard from "./IconCard";
import { TABLES } from "../shared/schema";
import "./EventList.scss";

const enhance = compose(
  firebaseConnect((props) => [
    { path: TABLES.EVENTS.firebaseRef, queryParams: ["orderByChild=date"] },
  ]),
  connect(({ firebase }) => ({
    events: firebase.data[TABLES.EVENTS.firebaseRef],
  }))
);

class EventList extends Component {
  constructor() {
    super();
    this.state = { isOpen: false };
    this.togglePast = this.togglePast.bind(this);
    this.renderEvent = this.renderEvent.bind(this);
  }

  renderEvent(event, idx) {
    return (
      <IconCard
        key={idx}
        Icon={FaCalendarAlt}
        title={event.title}
        href={event.rsvpLink}
        date={event.date}
        onClick={() => {
          window.analytics.track(CLICK_ENTRY, {
            section: Sections.EVENTS,
            item: event,
          });
        }}
        imageUrl={event.coverImage}
      />
    );
  }

  togglePast(e) {
    if (!this.state.isOpen) {
      const height = window.getComputedStyle(this.pastInner).height;
      this.past.style.maxHeight = height;
    } else {
      this.past.style.maxHeight = 0;
    }

    if (!this.state.isOpen) {
      window.analytics.track(EXPAND_SECTION, {
        section: Sections.EVENTS,
      });
    }

    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const events = Object.values(this.props.events || {});

    if (!isLoaded(events)) {
      return (
        <div className="EventList">
          <div className="placeholder">
            <span className="Spinner dark" />
          </div>
        </div>
      );
    }

    const upcomingEvents = events
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 8)
      .map(this.renderEvent);
    const pastEvents = events
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(8)
      .map(this.renderEvent);

    const pastClassNames = classNames("past", { "is-open": this.state.isOpen });

    return (
      <div className="EventList">
        <div className="cardlist upcoming">{upcomingEvents}</div>
        <div
          ref={(past) => {
            this.past = past;
          }}
          className={pastClassNames}
        >
          <div
            className="cardlist past-inner"
            ref={(pastInner) => {
              this.pastInner = pastInner;
            }}
          >
            {pastEvents}
          </div>
        </div>
        <div className="see-more" onClick={this.togglePast}>
          {this.state.isOpen ? "See Less" : "See All"}{" "}
          <FaChevronDown color="#000" />
        </div>
      </div>
    );
  }
}

export default enhance(EventList);

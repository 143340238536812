import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './Button.scss';

class Button extends Component {
  render() {
    const { href, to, color, children, target, onClick, disabled } = this.props;
    const className = classNames({
      'Button': true,
      [color]: color,
      'disabled': disabled,
    });

    if (disabled) {
      return (
        <span className={className}>{children}</span>
      );
    }

    if (href) {
      return (
        <a onClick={onClick} href={href} className={className} target={target}>{children}</a>
      );
    } else {
      return (
        <Link onClick={onClick} to={to} className={className}>{children}</Link>
      );
    }
  }
}

export default Button;

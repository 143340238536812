import moment from 'moment';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FaEnvelope, FaUsers, FaPencilRuler, FaArrowRight } from 'react-icons/fa';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';

import Button from './Button';
import MemberChip from './MemberChip';
import { ModalType } from '../constants/enums';
import * as ModalActions from '../actions/ModalActions';
import { TABLES, UPDATE_TYPES } from '../shared/schema';

import './MemberUpdateList.scss';

const enhance = compose(
  firebaseConnect((props) => [
    { path: TABLES.UPDATES.firebaseRef, queryParams: ['orderByChild=date'] },
    { path: TABLES.MEMBERS.firebaseRef },
  ]),
  connect(
    ({ firebase }) => ({
      updates: firebase.data[TABLES.UPDATES.firebaseRef],
      members: firebase.data[TABLES.MEMBERS.firebaseRef],
    }),
    (dispatch, ownProps) => ({
      openEditProjectModal: (project) => (e) => {
        e.preventDefault();
        dispatch(ModalActions.openModal(ModalType.EDIT_PROJECT_MODAL, {project}));
      },
      openCreateProjectModal: (e) => {
        e.preventDefault();
        dispatch(ModalActions.openModal(ModalType.EDIT_PROJECT_MODAL));
      },
    })
  )
);

class MemberUpdateList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleting: {}
    }
  }

  renderUpdateType(update) {
    switch (update?.type) {
      case UPDATE_TYPES.COMMUNITY_UPDATE:
        return <h6><FaEnvelope /> {update.newsletterIssue} Community Update</h6>;
      case UPDATE_TYPES.CIRCLE:
        return <h6><FaUsers /> Circle</h6>;
      case UPDATE_TYPES.PROJECT:
        return <h6><FaPencilRuler /> Collaboration</h6>;
      default:
        return<h6><FaPencilRuler /> {update.type}</h6>;
    }
  }

  renderEditControls(update) {
    const { openEditProjectModal } = this.props;
    const isDeleting = this.state.deleting[update.airtableId];
    const deleteButtonText = isDeleting ? 'Deleting...' : 'Delete';

    switch (update?.type) {
      case UPDATE_TYPES.CIRCLE:
      case UPDATE_TYPES.COMMUNITY_UPDATE:
        return (
          <h5>Added automatically</h5>
        )
      case UPDATE_TYPES.PROJECT:
      default:
        return (
          <React.Fragment>
            <Button color="green" href="#" onClick={openEditProjectModal(update)}>Edit</Button>
            <Button onClick={this.deleteProject(update)} color="red-line" href="#">{deleteButtonText}</Button>
          </React.Fragment>
        );
    }
  }

  deleteProject(update) {
    return (e) => {
      e && e.preventDefault();
      const projectId = update.airtableId;
      if (this.state.deleting[projectId]) {
        return;
      }

      const { firebase } = this.props;

      const deleteProject = firebase.functions().httpsCallable('project-delete');
      this.setState({
        deleting: {
          ...this.state.deleting,
          [projectId]: true,
        }
      });
      deleteProject({projectId})
        .catch((error) => {
          console.error("Error deleting:", error)
        });
      return false;
    }
  }

  render() {
    const { members, member, updates, editable, openCreateProjectModal } = this.props;

    if (!isLoaded(updates)) {
      return null;
    }

    const memberUpdates = member
      .communityUpdates?.map(updateId => updates[updateId])
      .sort((a, b) => b.date.localeCompare(a.date));

    if (!memberUpdates) {
      return null;
    }

    return (
      <ol className={`MemberUpdateList ${editable ? 'editable' : ''}`}>
        { editable && (
          <li className="update">
            <div className="update-date" />
            <div className="update-content">
              <Button onClick={openCreateProjectModal} color="green" href="#">Add collaboration</Button>
            </div>
          </li>
        )}
        {memberUpdates.map((update, idx) => (
          update && (
            <li key={idx} className="update">
              <h6 className="update-date">{moment(update.date).format('MMM, YYYY')}</h6>
              <div className="update-content">
                {editable && (
                  <div className="edit-controls">
                    { this.renderEditControls(update) }
                  </div>
                )}
                <p>{update.update}</p>
                { members && update.collaborators && (
                <div className="collaborators">
                  { update.collaborators.map((collaboratorId, idx) => (
                    <MemberChip disabled={editable} member={members[collaboratorId]} key={idx}/>
                  ))}
                </div>
                )}
                <div className="update-footer">
                  { this.renderUpdateType(update) }
                  { update.link && (
                    <Button color="green-text with-arrow" disabled={editable} href={update.link}>Visit <FaArrowRight /></Button>
                  )}
                </div>
              </div>
            </li>
          )
        ))}
      </ol>
    );
  }
}

export default enhance(MemberUpdateList);

import { firebaseConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { TABLES } from "../shared/schema";

export const withCurrentMember = compose(
  connect(({ firebase }) => ({
    auth: firebase.auth,
  })),
  firebaseConnect((props) => [
    {
      path: TABLES.WHITELIST.firebaseRef,
      queryParams: ["orderByChild=emailAddress", `equalTo=${props.auth.email}`],
      storeAs: "whitelistEntry",
    },
  ]),
  connect(({ firebase }) => ({
    userNotFoundInWhitelist:
      isLoaded(firebase.data.whitelistEntry) &&
      firebase.data.whitelistEntry === null,
    airtableId:
      isLoaded(firebase.data.whitelistEntry) &&
      firebase.data.whitelistEntry !== null &&
      Object.values(firebase.data.whitelistEntry)[0].member[0],
  })),
  firebaseConnect((props) =>
    props.airtableId
      ? [
          {
            path: `${TABLES.MEMBERS.firebaseRef}/${props.airtableId}`,
            storeAs: "currentMember",
          },
        ]
      : []
  ),
  connect(({ firebase }) => ({
    currentMember: firebase.data.currentMember,
  }))
);

import React from 'react';
import { TABLES } from '../../shared/schema';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Multiselect } from 'multiselect-react-dropdown';

const optionSort = (a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

const enhance = compose(
  firebaseConnect((props) => [
    { path: TABLES.MEMBERS.firebaseRef },
  ]),
  connect(
    ({ firebase }) => ({
      members: firebase.data[TABLES.MEMBERS.firebaseRef],
    })
  )
);

class MultiSelectField extends React.Component {

  uniqueValuesForField(fieldName) {
    const members = Object.values(this.props.members);

    let values = new Set();

    for (let i = 0; i < members.length; i++) {
      const f = members[i][fieldName];
      if (!f) continue;
      if (f.constructor === Array) {
        f.forEach((f_entry) => {
          values.add(f_entry);
        });
      } else {
        values.add(f)
      }
    }

    return Array.from(values).map(v => ({id: v, name: v}));
  }

  getMultiselectValues(fieldId, values) {
    let ret, ids;
    switch(fieldId) {
      case 'pronouns':
      case 'interests':
      case 'skills':
        ids = (values === 'all') ? TABLES.MEMBERS.fieldSchemas[fieldId].options : (values || []);
        ret = ids.map(id => ({ id: id, name: id }));
        break;
      case 'collaborators':
        const {members} = this.props;
        ids = (values === 'all') ? Object.keys(members) : (values || []);
        ids = ids.filter(id => !!members[id].fullName);
        ret = ids.map(id => ({id: id, name: members[id].fullName})).filter(obj => obj.name.trim() !== '');
        break;
      default:
        if (values === 'all') {
          ret = this.uniqueValuesForField(fieldId)
        } else {
          ret = (values || []).map(v => ({id: v, name: v}))
        }
        break;
    }
    return ret.sort(optionSort);
  }

  render() {
    const {id, ref, label, value, error, tableSchema, onChange, members, ...otherProps} = this.props;
    const hasError = error?.field === id;

    let control;
    if (!members) {
      control = (<input type="text" placeholder={otherProps.placeholder} />);
    } else {
      control = (
        <Multiselect
          options={this.getMultiselectValues(id, 'all')} // Options to display in the dropdown
          closeIcon="cancel"
          displayValue="name"
          id={id}
          ref={ref}
          onSelect={(list, value) => onChange(id, list.map(obj => obj.id))}
          onRemove={(list, value) => onChange(id, list.map(obj => obj.id))}
          style={{
            searchBox: {
              border: hasError ? '1px solid #CF3E3E' : '1px solid #D0D0D0',
              borderRadius: '0',
              display: 'flex',
              flexWrap: 'wrap',
              padding: '0.25rem 0.5rem',
              background: 'white',
              alignItems: 'center',
            },
            inputField: {
              border: '0',
              padding: '0 0.5rem 0 0',
              margin: '0',
              width: 'auto',
            },
            chips: {
              background: '#D0D0D0',
              color: '#3A3836',
              fontSize: '0.875rem',
              borderRadius: '4px',
              padding: '0.25rem 0.5rem',
              margin: '0.25rem 0.5rem 0.25rem 0',
            },
            optionContainer: {
              borderRadius: '0',
            },
            option: {
              color: '#3A3836',
            },
          }}
          selectedValues={this.getMultiselectValues(id, value)} // Preselected value to persist in dropdown
          {...otherProps}
        />
      )
    }

    return (
      <div className={`form-control ${hasError ? 'has-error' : ''}`}>
        <label htmlFor={id}>{label} {tableSchema.fieldSchemas[id]?.required ? <span className="required" />: ''}</label>
        <div className="multiselect-wrapper">
          { control }
        </div>
        {hasError && (
          <div className="error">{error.error}</div>
        )}
      </div>
    );
  }
}

export default enhance(MultiSelectField);

import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Button from '../components/Button';
import Footer from '../components/Footer';

import './NotFoundPage.scss';


class NotFoundPage extends Component {

  componentDidMount() {
    window.analytics.page('NotFoundPage');
  }

  render() {
    return (
      <div className="NotFoundPage">
        <Navbar loggedOut={true}/>
        <section>
          <div className="content">
            <h2>Not Found</h2>
            <p>We couldn't find the page you're looking for. Check your URL or go home.</p>
            <Button
              to="/home"
              color="green-line small">
                Go home
            </Button>
          </div>
        </section>
        <Footer />
      </div>
    );
  }

}

export default NotFoundPage;






import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {EXPAND_SECTION, CLICK_ENTRY, Sections} from '../constants/Analytics';
import { FaTwitter, FaLinkedin, FaChevronRight } from 'react-icons/fa';
import './VentureFirmList.scss'
import {TABLES} from '../shared/schema';

const enhance = compose(
  withRouter,
  firebaseConnect((props) => [
    { path: TABLES.VENTURE_FIRMS.firebaseRef },
    { path: TABLES.VENTURE_PARTNERS.firebaseRef },
    { path: TABLES.VENTURE_ENDORSEMENTS.firebaseRef },
  ]),
  connect(({ firebase }) => ({
    firms: firebase.data[TABLES.VENTURE_FIRMS.firebaseRef],
    partners: firebase.data[TABLES.VENTURE_PARTNERS.firebaseRef],
    endorsements: firebase.data[TABLES.VENTURE_ENDORSEMENTS.firebaseRef],
  }))
);

class Partner extends Component {

  openPartnerModal(e) {
    const { history, partner } = this.props;
    window.analytics.track(CLICK_ENTRY, {
      section: Sections.VENTURE,
      item: partner,
    });
    history.push(`/partner/${partner.slug}`);
  }

  render() {
    const { partner, endorsements } = this.props;
    partner.endorsements = endorsements;
    const focusAreas = partner.focusAreas?.split(',').map(s => s.trim());
    return (
      <div className="Partner" onClick={() => this.openPartnerModal()}>
        <div className="left-side">
          <div className="partner-header">
            <i style={{backgroundImage: `url(${partner.linkToHeadshot})`}} />
            <div>
              <h3>{partner.fullName}</h3>
              <ul className="social-links">
                {partner.linkedinProfileUrl && (
                  <li>
                    <a href={partner.linkedinProfileUrl}>
                      <FaLinkedin />
                      <span className="social-text">LinkedIn</span>
                    </a>
                  </li>
                )}
                {partner.twitterUsername && (
                  <li>
                    <a href={`https://twitter.com/${partner.twitterUsername}`}>
                      <FaTwitter />
                      <span className="social-text">Twitter</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        { focusAreas && (
          <ul className="focus-areas">
            {focusAreas.map((fa, faIdx) => (
              <li className="Chip" key={faIdx}>{fa}</li>
            ))}
          </ul>
        )}
        <FaChevronRight className="more-icon" />
      </div>
    );
  }
}

class VentureFirm extends Component {
  constructor() {
    super();
    this.state = { isOpen: false }
    this.toggleMore = this.toggleMore.bind(this);
  }

  toggleMore(e) {
    if (!this.state.isOpen) {
      const height = window.getComputedStyle(this.pastInner).height;
      this.past.style.maxHeight = height;
    } else {
      this.past.style.maxHeight = 0;
    }

    if (!this.state.isOpen) {
      window.analytics.track(EXPAND_SECTION, {
        section: Sections.EVENTS
      });
    }

    this.setState({isOpen: !this.state.isOpen});
  }

  render () {
    const {firm, partners, endorsements, history} = this.props;
    return (
      <div className="VentureFirm">
        <div className="firm-partners">
          {
            partners.filter(p => p.firm === firm.name).slice(0, 2).map((partner, pIdx) => {
              const partnerEndorsements = endorsements && endorsements.filter(e => e.partnerFullName === partner.fullName);
              return (
                <Partner partner={partner} endorsements={partnerEndorsements} key={pIdx} history={history}/>
              )
            })
          }
        </div>
        <div className="firm-description">
          <i style={{backgroundImage: `url(${firm.logo})`}} />
          <p className="small">{ firm.description }</p>
        </div>
      </div>
    )
  }
}

class VentureFirmList extends Component {

  render() {
    const { history } = this.props;
    const firms = Object.values(this.props.firms || {}).filter(p => p.name !== undefined);
    const partners = Object.values(this.props.partners || {}).filter(p => p.fullName !== undefined);
    const endorsements = Object.values(this.props.endorsements || {});

    if (!isLoaded(partners) || !isLoaded(firms)) {
      return (
        <div>
          <div className="placeholder">
            <span className="Spinner dark"/>
          </div>
        </div>
      );
    }

    return (
      <div className="VentureFirmList">
        {firms && firms.map((firm, fIdx) => (
          <VentureFirm key={fIdx} firm={firm} partners={partners} endorsements={endorsements} history={history}/>
        ))}
      </div>
    );
  }
}

export default enhance(VentureFirmList);

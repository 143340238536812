import * as ActionTypes from '../constants/ActionTypes';

export const openModal = (modalType, modalProps, context) => ({
  type: ActionTypes.MODAL_OPEN,
  modalType,
  modalProps,
  context,
});

export const closeModal = () => ({
  type: ActionTypes.MODAL_CLOSE,
});

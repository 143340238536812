import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as UserActions from '../actions/UserActions';

const enhance = compose(
  withRouter,
  withFirebase,
  connect(
    null,
    (dispatch, ownProps) => ({
      logout: claims => {
        ownProps.firebase.logout();
        dispatch(UserActions.logout());
      },
    }))
);

class LogoutPage extends Component {

  constructor(props) {
    super(props);
    const { history, logout } = props;
    logout();
    history.replace('/');
  }

  render() {
    return null;
  }
}

export default enhance(LogoutPage);


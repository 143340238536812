import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { compose } from 'redux';

import './index.scss';

const enhance = compose(
  connectSearchBox,
);


class MemberSearchBox extends React.Component {

  render() {
    const {currentRefinement, refine} = this.props;

    return (
      <div className="MemberSearchBox">
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          maxLength="512"
          onChange={event => refine(event.currentTarget.value)}
          placeholder="Search by name, company, cohort, interests, and more..."
          spellCheck="false"
          type="search"
          value={currentRefinement}
        />
      </div>
    );
  }
}

export default enhance(MemberSearchBox);

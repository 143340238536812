import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import { applyMiddleware, createStore } from "redux";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import initialState from "./initialState";
import createRootReducer from "./reducers";
import firebase from "firebase/app";
import App from "./App";

import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";

const rrfConfig = {
  logErrors: true,
  // enableLogging: true,
  logListenerError: true,
};

const firebaseConfig = {
  apiKey: "AIzaSyBkSq0wFoHolpgw6Wx-qFll1EylbN4hFsI",
  authDomain: "members.joininteract.com",
  databaseURL: "https://interact-members.firebaseio.com",
  projectId: "interact-members",
  storageBucket: "interact-members.appspot.com",
  messagingSenderId: "504252729707",
  appId: "1:504252729707:web:424de1cdf92c1d32",
};
firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.performance();

LogRocket.init("hzoc2w/interact-member-portal");
setupLogRocketReact(LogRocket);

export const history = createBrowserHistory();
const middleware = applyMiddleware(
  routerMiddleware(history),
  LogRocket.reduxMiddleware()
  // thunkMiddleware
  // createLogger()
);

const store = createStore(createRootReducer(history), initialState, middleware);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const searchClient = algoliasearch(
  "09B0I1IVQY",
  "e659c52a263477fa5c982b0a7376dfc1"
);

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ConnectedRouter history={history}>
        <InstantSearch searchClient={searchClient} indexName="members">
          <App />
        </InstantSearch>
      </ConnectedRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();

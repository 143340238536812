import React, { Component } from "react";
import MemberSearchBox from "./MemberSearchBox";
import { List } from "react-virtualized";
import { connect } from "react-redux";
import { compose } from "redux";

import "./index.scss";

const algoliaSort = (a, b) => a.position - b.position;

const enhance = compose(
  connect(({ modal }) => ({
    currentModal: modal,
  }))
);

class FilterableTable extends Component {
  constructor() {
    super();
    this.state = {
      showAll: false,
      showFilterControlsFor: null,
      filters: {},
      tableBodyWidth: 0,
      tableBodyHeight: 0,
      rem: 0,
    };
    this.toggleShowAll = this.toggleShowAll.bind(this);
    this.toggleFilterControlsFor = this.toggleFilterControlsFor.bind(this);
    this.hideFilterControls = this.hideFilterControls.bind(this);
    this.toggleFilterOptionFor = this.toggleFilterOptionFor.bind(this);
    this.clearFilterFor = this.clearFilterFor.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.onResize();
    document.addEventListener("keydown", this.onKeyDown, false);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
    document.removeEventListener("keydown", this.onKeyDown, false);
    document.body.classList.remove("frozen");
  }

  onKeyDown(e) {
    switch (e.keyCode) {
      case 27: // Escape
        if (!this.props.currentModal) {
          this.toggleShowAll(null, "close");
        }
        break;
      default:
        break;
    }
  }

  onResize(e) {
    const tableBodyStyle = window.getComputedStyle(this.tableBody);
    this.setState({
      rem: parseInt(window.getComputedStyle(document.body).fontSize),
      tableBodyWidth: parseInt(tableBodyStyle.width),
      tableBodyHeight: parseInt(tableBodyStyle.height),
    });
  }

  toggleShowAll(_, command) {
    const { onShowAll } = this.props;
    if (!this.state.showAll && command !== "close") {
      onShowAll();
      document.body.classList.add("frozen");
    } else {
      document.body.classList.remove("frozen");
    }

    this.setState({
      showAll: command === "close" ? false : !this.state.showAll,
    });
    const intervalID = setInterval(() => {
      this.onResize();
    }, 16);
    setTimeout(() => {
      clearInterval(intervalID);
    }, 500);
  }

  toggleFilterControlsFor(fieldName) {
    return (e) => {
      e.stopPropagation();
      if (this.state.showFilterControlsFor === fieldName) {
        this.setState({
          showFilterControlsFor: null,
        });
      } else {
        this.setState({
          showFilterControlsFor: fieldName,
        });
      }
    };
  }

  hideFilterControls() {
    this.setState({ showFilterControlsFor: null });
  }

  toggleFilterOptionFor(fieldName) {
    return (value) => {
      return (e) => {
        e.stopPropagation();
        this.setState((state) => {
          if (!state.filters[fieldName]) {
            state.filters[fieldName] = {};
          }

          if (state.filters[fieldName][value]) {
            // Toggle On
            delete state.filters[fieldName][value];
            if (!Object.keys(state.filters[fieldName]).length) {
              delete state.filters[fieldName];
            }
          } else {
            // Toggle On
            state.filters[fieldName][value] = true;
          }

          return state;
        });
      };
    };
  }

  clearFilterFor(fieldName) {
    return () => {
      this.setState((state) => {
        delete state.filters[fieldName];
        return state;
      });
    };
  }

  applyFilters(rows, fieldToSkip) {
    const { filters } = this.state;
    const { hits, searchEnabled } = this.props;
    let hitsMap = {};
    (hits || []).forEach((hit) => {
      hitsMap[hit.airtableId] = hit;
    });

    return rows.filter((row) => {
      if (searchEnabled) {
        if (hitsMap && !hitsMap[row.airtableId]) {
          return false;
        } else {
          row.position = hitsMap[row.airtableId].__position;
        }
      }
      return Object.keys(filters).every((fieldName) => {
        if (fieldName === fieldToSkip) {
          return true;
        }
        if (Object.keys(filters[fieldName]).length > 0) {
          if (typeof row[fieldName] === "string") {
            return row[fieldName]
              .split(", ")
              .some((row_loc) => !!filters[fieldName][row_loc]);
          } else if (row[fieldName] && row[fieldName].constructor === Array) {
            return row[fieldName].some(
              (row_loc) => !!filters[fieldName][row_loc]
            );
          } else {
            return filters[fieldName][row[fieldName]];
          }
        }
        return true;
      });
    });
  }

  getUniqueEntriesWithCountsForField(rows, fieldName, sortingMethod) {
    const filteredRows = this.applyFilters(rows, fieldName);
    let counts = [];
    let i, f;

    // Get counts
    for (i = 0; i < filteredRows.length; i++) {
      f = filteredRows[i][fieldName];
      if (!f) continue;
      if (typeof f === "string") {
        f.split(", ").forEach((f_entry) => {
          if (counts[f_entry]) counts[f_entry]++;
          else counts[f_entry] = 1;
        });
      } else if (f.constructor === Array) {
        f.forEach((f_entry) => {
          if (counts[f_entry]) counts[f_entry]++;
          else counts[f_entry] = 1;
        });
      } else {
        if (counts[f]) counts[f]++;
        else counts[f] = 1;
      }
    }

    const entries = Object.entries(counts);
    let sorted;
    if (sortingMethod === "count") {
      // Sort descending by count then ascending by name
      sorted = entries.sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0]));
    } else if (sortingMethod === "ascending") {
      // Sort ascending by name
      sorted = entries.sort((a, b) => a[0].localeCompare(b[0]));
    } else if (sortingMethod === "descending") {
      sorted = entries.sort((a, b) => b[0].localeCompare(a[0]));
    }

    return sorted;
  }

  _parseColumns() {
    const { children } = this.props;
    return React.Children.map(children, (column, i) => {
      return {
        cid: column.props.cid,
        type: column.type,
        title: column.props.title,
        getContent: column.props.getContent,
        isFilterable: column.props.isFilterable,
        filterOptionsSortingMethod: column.props.filterOptionsSortingMethod,
      };
    });
  }

  render() {
    const {
      showAll,
      filters,
      showFilterControlsFor,
      tableBodyWidth,
      tableBodyHeight,
      rem,
    } = this.state;
    const { rows, limit, currentRefinement, searchEnabled, getOnClickRow } =
      this.props;

    const clickableRows = !!getOnClickRow;
    const shouldSort = searchEnabled && currentRefinement.length > 0;
    const isLimited = !!limit && rows.length > limit;
    const isLoaded = rows && rows.length > 0;
    const filteredRows = this.applyFilters(rows);
    const sortedRows = shouldSort
      ? filteredRows.sort(algoliaSort)
      : filteredRows;
    const rowsToRender =
      isLimited && !showAll ? sortedRows.slice(0, limit) : sortedRows;

    if (!this.columns) {
      this.columns = this._parseColumns();
    }

    const renderRow = ({ index, key, style, isScrolling }) => {
      const row = rowsToRender[index];
      return (
        <div
          className={`member table-row ${clickableRows ? "clickable" : ""}`}
          key={key}
          style={{
            ...style,
            display: "flex",
          }}
          onClick={!isScrolling && clickableRows ? getOnClickRow(row) : null}
        >
          {this.columns.map((column) => (
            <column.type
              mode="body"
              row={row}
              key={`${key}-${column.cid}`}
              cid={column.cid}
              type={column.type}
              title={column.title}
              getContent={column.getContent}
              isFilterable={column.isFilterable}
              filterOptionsSortingMethod={column.filterOptionsSortingMethod}
            />
          ))}
        </div>
      );
    };

    const tableBody = isLoaded ? (
      isLimited && rowsToRender.length > 20 ? (
        <List
          width={tableBodyWidth}
          height={Math.max(tableBodyHeight, 10 * 4 * rem)}
          rowHeight={4 * rem}
          rowCount={rowsToRender.length}
          rowRenderer={renderRow}
        />
      ) : (
        rowsToRender.map((row, idx) => renderRow({ index: idx, key: idx }))
      )
    ) : (
      <div className="placeholder">
        <span className="Spinner dark" />
      </div>
    );

    const tableHeader = this.columns.map((column) => {
      const filterOptionsSortingMethod =
        column.filterOptionsSortingMethod || "count";
      let isFilterable = isLoaded && column.isFilterable;
      const cid = column.cid;
      const filterOptions = isFilterable
        ? this.getUniqueEntriesWithCountsForField(
            rows,
            cid,
            filterOptionsSortingMethod
          )
        : undefined;
      return (
        <column.type
          mode="header"
          key={cid}
          filterOptions={filterOptions}
          isFilterControlsShown={showFilterControlsFor === cid}
          isFilterable={isFilterable}
          activeFilters={filters[cid]}
          clearFilter={this.clearFilterFor(cid)}
          toggleFilterOption={this.toggleFilterOptionFor(cid)}
          toggleFilterControls={this.toggleFilterControlsFor(cid)}
          hideFilterControls={this.hideFilterControls}
          cid={cid}
          type={column.type}
          title={column.title}
          getContent={column.getContent}
          filterOptionsSortingMethod={column.filterOptionsSortingMethod}
        />
      );
    });

    return (
      <div className="table-wrapper-outer">
        <div className="table-wrapper">
          <div className="table-wrapper-inner">
            <div
              className={`FilterableTable table ${showAll ? "expanded" : ""} ${
                isLimited ? "has-show-all" : ""
              }`}
            >
              {searchEnabled && <MemberSearchBox />}
              <div className="table-header">{tableHeader}</div>
              <div className="table-body" ref={(div) => (this.tableBody = div)}>
                {tableBody}
              </div>
            </div>
          </div>
        </div>
        {isLimited && (
          <button
            className={`show-all ${showAll ? "is-open" : ""}`}
            onClick={this.toggleShowAll}
          >
            {showAll
              ? "Exit full screen"
              : "See all " + (filteredRows.length || "") + " members"}
          </button>
        )}
      </div>
    );
  }
}

export default enhance(FilterableTable);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import './ProfileOnboardingJourney.scss';

export const getOnboardingStatus = (currentMember) => {
  const journeySegments = {
    'Add your first and last name.': currentMember.firstName?.length > 0 && currentMember.lastName?.length > 0,
    'Add a first-person intro.': currentMember.firstPersonIntro?.length > 0,
    'Add your current company and role.': currentMember.currentCompanyAndRole?.length > 0,
    'Add your current location.': currentMember.currentLocation?.length > 0,
    'Add your interests.': currentMember.interests?.length > 0,
    'Add your skills.': currentMember.skills?.length > 0,
    'Add a profile picture.': !!currentMember.linkToHeadshot,
  };

  const totalSegments = Object.values(journeySegments).length;
  const segmentsComplete = Object.values(journeySegments).filter((x => x)).length;
  const nextAction = Object.keys(journeySegments).filter(key => !journeySegments[key])[0] || 'Profile complete!';

  return { totalSegments, segmentsComplete, nextAction };
}

const enhance = compose(
  withRouter,
  connect(
    ({ profile }) => ({
      profile,
    })
  )
);

class ProfileOnboardingJourney extends Component {

  constructor(props) {
    super(props);
    this.state =  {
      showForRestOfSession: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { profile } = nextProps;
    const { showForRestOfSession } = prevState;

    const { segmentsComplete, totalSegments, nextAction } = getOnboardingStatus(profile.fields);
    const prefix =
      segmentsComplete === 0
      ? 'First: '
      : segmentsComplete === totalSegments - 1
        ? 'Finally:'
        : segmentsComplete === totalSegments
          ? ''
          : 'Next: ';
    const percentComplete = segmentsComplete / totalSegments;

    return {
      prefix,
      percentComplete,
      nextAction,
      segmentsComplete,
      totalSegments,
      segmentsIncomplete: (totalSegments - segmentsComplete),
      showForRestOfSession: (showForRestOfSession || percentComplete !== 1)
    };
  }

  render() {
    const { profile, condensed } = this.props;
    const { showForRestOfSession, prefix, percentComplete, nextAction, segmentsIncomplete } = this.state;
    if (!profile.fields) {
      return null;
    }

    if (percentComplete === 1 && !showForRestOfSession) {
      return null;
    }

    return (
      <div className={`ProfileOnboardingJourney ${condensed ? 'condensed' : ''}`}>
        {!condensed && <p>Complete your profile to view and search for Interact members.</p>}
        <div className="progress-bar">
          <div className="progress" style={{width: `${percentComplete * 100}%`}} />
        </div>
        { condensed && (<h6>{segmentsIncomplete} field{segmentsIncomplete !== 1 ? 's' : ''} remaining</h6>)}
        { !condensed && nextAction && (
          <p className="next-action small" style={{
            marginLeft: `${percentComplete * 100}%`,
            transform: (percentComplete < 0.2
                          ? 'translateX(0%)'
                          : percentComplete > 0.95
                          ? 'translateX(-100%)'
                          : 'translateX(-50%)'),
            textAlign: (percentComplete < 0.2
                          ? 'left'
                          : percentComplete > 0.95
                          ? 'right'
                          : 'center'),
          }}>{prefix} {nextAction}</p>
        )}
      </div>
    )
  }
}

export default enhance(ProfileOnboardingJourney);

const objectFlip = (obj) => {
  const ret = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      ret[obj[key]] = key;
    }
  });
  return ret;
};

exports.CONTACT_PREFERENCES = {
  MEETINGS: "1-1 meetings",
  QUESTIONS: "Specific questions",
  COLLABORATIONS: "Collaborations",
  EVENTS: "Events",
};

exports.PRONOUNS = [
  "he / him / his",
  "she / her / hers",
  "sie / hir / hirs",
  "they / them / theirs",
  "zie / zir / zirs",
  "ask me about my pronouns",
];

exports.UPDATE_TYPES = {
  CIRCLE: "Circle",
  COMMUNITY_UPDATE: "Community Update",
  PROJECT: "Project",
};

exports.INTERESTS = [
  "Account Management",
  "Acting & Theater Performance",
  "Activism",
  "Agriculture",
  "AR",
  "Architecture",
  "Artificial Intelligence",
  "Astronomy",
  "Backend",
  "Biotech",
  "Blockchain",
  "Brand Development",
  "Brand Identity",
  "Business Development",
  "Civics",
  "Climate Science",
  "Coaching",
  "Community Building",
  "Cryptocurrency",
  "Cryptography",
  "Cybersecurity",
  "Data Engineering",
  "Data Science",
  "Defense",
  "Developer Tools",
  "Distributed Systems",
  "Diversity and Inclusion",
  "Earth Sciences",
  "eCommerce",
  "Economics",
  "Education",
  "Energy",
  "Fashion",
  "Film",
  "Fitness",
  "For-profit Fundraising",
  "Game Development",
  "Genetics",
  "Government",
  "Growth Hacking",
  "Hardware",
  "Healthcare",
  "History",
  "Investing",
  "Journalism",
  "Law",
  "Leadership",
  "Machine Learning",
  "Manufacturing",
  "Marketing",
  "Medicine",
  "Military",
  "Mobile Development",
  "Music Performance",
  "National Security",
  "Neuroscience",
  "Not-for-profit Fundraising",
  "Nutrition",
  "Operations",
  "People Management",
  "Photography",
  "Poetry",
  "Policy",
  "Politics",
  "Product",
  "Product Design",
  "Production",
  "Program Management",
  "Psychology",
  "Public Relations",
  "Real Estate",
  "Recruiting",
  "Renewable Energy",
  "Research",
  "Sales Operations",
  "Space Exploration",
  "Storytelling",
  "Teaching",
  "Television",
  "Theatre Arts (Cast & Crew)",
  "Transportation",
  "Urban Planning",
  "UX Design",
  "Visual & Media Arts",
  "VR",
  "Web Development",
  "Writing",
];

let _TABLES = {
  MEMBERS: {
    airtableTableName: "Members",
    airtableViewName: "Firebase Website Sync",
    firebaseRef: "members",
    airtableToFirebase: {
      Affiliations: null,
      Age: null,
      Birthday: null,
      Cohort: "cohort",
      Collaborators: "collaborators",
      "Community Updates": "communityUpdates",
      "Contact Preferences": "contactPreferences",
      "Current Company and Role": "currentCompanyAndRole",
      Events: null,
      "Facebook Profile URL": "facebookProfileUrl",
      "First Name": "firstName",
      "First-Person Intro": "firstPersonIntro",
      "Full Name": "fullName",
      Gender: null,
      Interests: "interests",
      "Last Name": "lastName",
      "Linkedin Profile URL": "linkedinProfileUrl",
      Location: "currentLocation",
      "Name Pronunciation": "namePronunciation",
      Notes: null,
      "Phone Number": null,
      "Primary Key": "emailAddress",
      "Profile Picture": "linkToHeadshot",
      Pronouns: "pronouns",
      "Race / Ethnicity": null,
      Skills: "skills",
      Slug: "slug",
      "Standing Offers": null,
      Team: "team",
      "Twitter Handle": "twitterUsername",
    },
    fieldSchemas: {
      emailAddress: {
        required: true,
      },
      currentCompanyAndRole: {
        minLength: 2,
        maxLength: 80,
      },
      firstName: {
        required: true,
        maxLength: 40,
        minLength: 2,
      },
      lastName: {
        required: true,
        maxLength: 40,
        minLength: 2,
      },
      firstPersonIntro: {
        maxLength: 280,
      },
      twitterUsername: {
        maxLength: 16,
        minLength: 2,
      },
      namePronunciation: {
        maxLength: 80,
      },
      pronouns: {
        options: exports.PRONOUNS,
      },
      contactPreferences: {
        options: [
          {
            id: exports.CONTACT_PREFERENCES.MEETINGS,
            title: exports.CONTACT_PREFERENCES.MEETINGS,
          },
          {
            id: exports.CONTACT_PREFERENCES.QUESTIONS,
            title: exports.CONTACT_PREFERENCES.QUESTIONS,
          },
          {
            id: exports.CONTACT_PREFERENCES.COLLABORATIONS,
            title: exports.CONTACT_PREFERENCES.COLLABORATIONS,
          },
          {
            id: exports.CONTACT_PREFERENCES.EVENTS,
            title: exports.CONTACT_PREFERENCES.EVENTS,
          },
        ],
      },
      fullName: {
        readOnly: true,
      },
      cohort: {
        readOnly: true,
      },
      slug: {
        readOnly: true,
      },
      airtableId: {
        readOnly: true,
      },
      interests: {
        options: exports.INTERESTS,
      },
      skills: {
        options: exports.INTERESTS.concat(["Patent Filing"]),
      },
    },
    firebaseToAirtable: {
      geoLocation: null, // Don't sync geoLocation back to airtable.
    }, // populated below
    airtableFieldsInUse: null, // populated below
  },
  RESOURCES: {
    airtableTableName: "Resources",
    airtableViewName: "Grid view",
    firebaseRef: "resources",
    airtableToFirebase: {
      Title: "title",
      Link: "link",
      Icon: "icon",
      "Cover Image": "coverImage",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  NEWSLETTERS: {
    airtableTableName: "Newsletters",
    airtableViewName: "Grid view",
    firebaseRef: "newsletters",
    airtableToFirebase: {
      Quarter: "quarter",
      Link: "link",
      "Community Updates": "communityUpdates",
      "Cover Image": "coverImage",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  TEAM: {
    airtableTableName: "Team",
    airtableViewName: "Active",
    firebaseRef: "team",
    airtableToFirebase: {
      Member: "member",
      Team: "team",
      Role: "role",
      "Introduction Link": "introLink",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  VENTURE_FIRMS: {
    airtableTableName: "Venture Firms",
    airtableViewName: "Grid view",
    firebaseRef: "ventureFirms",
    airtableToFirebase: {
      Name: "name",
      Description: "description",
      Logo: "logo",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  VENTURE_PARTNERS: {
    airtableTableName: "Venture Partners",
    airtableViewName: "Grid view",
    firebaseRef: "venturePartners",
    airtableToFirebase: {
      "First Name": null,
      "Last Name": null,
      "Full Name": "fullName",
      Slug: "slug",
      Title: "title",
      Firm: "firm",
      Bio: "bio",
      "Focus Areas": "focusAreas",
      "Link to Headshot": "linkToHeadshot",
      "Twitter Username": "twitterUsername",
      "Linkedin Profile URL": "linkedinProfileUrl",
      "Message To Members": "messageToMembers",
      Notes: null,
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  VENTURE_ENDORSEMENTS: {
    airtableTableName: "Venture Endorsements",
    airtableViewName: "Grid view",
    firebaseRef: "venturePartnerEndorsements",
    airtableToFirebase: {
      "Partner Full Name": "partnerFullName",
      "Endorser Full Name": "endorserFullName",
      "Endorser Cohort": "endorserCohort",
      "Endorser Current": "endorserCurrent",
      "Endorser Email": "endorserEmail",
      Endorsement: "endorsement",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  JOB_BOARD: {
    airtableTableName: "Job Board",
    airtableViewName: "Grid view",
    firebaseRef: "jobBoard",
    airtableToFirebase: {
      Timestamp: "timestamp",
      "Email Address": "emailAddress",
      "Item Title": "itemTitle",
      "Item Description": "itemDescription",
      "Item URL": "itemUrl",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  EVENTS: {
    airtableTableName: "Events",
    airtableViewName: "Firebase Website Sync",
    firebaseRef: "events",
    airtableToFirebase: {
      "Primary Key": null,
      Title: "title",
      Quarter: null,
      Status: null,
      Date: "date",
      Time: null,
      "Host(s) / Team Lead(s)": null,
      Location: null,
      City: null,
      "Open to": null,
      "RSVP Text": null,
      "RSVP Link": "rsvpLink",
      "Cover Image": "coverImage",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  WHITELIST: {
    airtableTableName: "Primary Email Lookup",
    airtableViewName: "Grid view",
    firebaseRef: "whitelist",
    airtableToFirebase: {
      "Primary Key": null,
      "Email Address": "emailAddress",
      Member: "member",
      "Is Primary": null,
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  COMPANY_AND_ROLE: {
    airtableTableName: "Company and Role",
    airtableViewName: "Primary View",
    firebaseRef: "currentCompanyAndRole",
    airtableToFirebase: {
      "Primary Key": null,
      Member: "member",
      "Company and Role": "companyAndRole",
      "Updated By": "updatedBy",
      "Created At": "createdAt",
      "Updated At": "updatedAt",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
  },
  UPDATES: {
    airtableTableName: "Updates",
    airtableViewName: "Grid view",
    firebaseRef: "memberUpdates",
    airtableToFirebase: {
      "Primary Key": null,
      Type: "type",
      Date: "date",
      Update: "update",
      Link: "link",
      Collaborators: "collaborators",
      "Newsletter Issue": "newsletterIssue",
      Member: "member",
    },
    firebaseToAirtable: null, // populated below
    airtableFieldsInUse: null, // populated below
    fieldSchemas: {
      type: {
        required: true,
        options: [
          exports.UPDATE_TYPES.CIRCLE,
          exports.UPDATE_TYPES.COMMUNITY_UPDATE,
          exports.UPDATE_TYPES.PROJECT,
        ],
      },
      date: {
        required: true,
      },
      collaborators: {
        required: true,
      },
      update: {
        required: true,
        maxLength: 500,
        minLength: 2,
      },
      newsletterIssue: {
        readOnly: true,
      },
    },
  },
};

// Create generated data
Object.values(_TABLES).forEach((table) => {
  table.firebaseToAirtable = Object.assign(
    {},
    table.firebaseToAirtable,
    objectFlip(table.airtableToFirebase)
  );
  table.airtableFieldsInUse = Object.keys(table.airtableToFirebase).filter(
    (key) => table.airtableToFirebase[key] !== null
  );
});

exports.AIRTABLE_BASE = "appgvcO8VmOtSaKE0";
exports.TABLES = _TABLES;

import React, { Component } from "react";

function _easeInOutQuad(x, t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
  return (-c / 2) * (--t * (t - 2) - 1) + b;
}

const scrollTo = function (offset, scrollDuration, cb) {
  cb = cb || function () {};
  var startT = Date.now();
  var startY = window.scrollY;
  var distanceToTravel = offset - startY;
  var percentComplete = 0;
  var elapsed;
  var scrollToPercent;
  var scrollToY;

  function step() {
    setTimeout(function () {
      if (percentComplete < 1) {
        elapsed = Date.now() - startT;
        percentComplete = elapsed / scrollDuration;
        scrollToPercent = _easeInOutQuad(
          percentComplete,
          elapsed,
          0,
          1,
          scrollDuration
        );
        scrollToY = scrollToPercent * distanceToTravel + startY;
        window.scrollTo(0, scrollToY);
        requestAnimationFrame(step);
      } else {
        window.scrollTo(0, offset);
        cb();
      }
    }, 15);
  }

  step();
};

class JumpLink extends Component {
  constructor() {
    super();
    this.jump = this.jump.bind(this);
  }

  jump(e) {
    const { href, onJump, offset } = this.props;
    e && e.preventDefault();

    const [path, hash] = href.split("#");
    if (!hash) return;
    console.log(path, hash);

    if (path !== "" && window.location.pathname !== path) {
      this.props.history.push(path);
      setTimeout(() => {
        this.jump();
      }, 500);
      return;
    }

    const elem = document.getElementById(hash);
    console.log(elem);
    if (!elem) {
      return;
    }

    const actualOffset =
      window.innerWidth < 768 ? (offset ? 64 : 0) : offset || 0;
    const y = elem.offsetTop - actualOffset;
    scrollTo(y, 500, null);
    onJump && onJump();
  }

  render() {
    const { href, children } = this.props;

    return (
      <a href={href} onClick={this.jump}>
        {children}
      </a>
    );
  }
}

export default JumpLink;

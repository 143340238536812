import React, { Component } from 'react';

import './Chip.scss';

class Chip extends Component {
  render() {
    const { children, size } = this.props;

    return (
      <li className={`Chip ${size}`}><span className="chip-inner">{children}</span></li>
    )
  }
}

export default Chip;

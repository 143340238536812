import React, { Component } from "react";
import { withFirebase } from "react-redux-firebase";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import "./Navbar.scss";
import RefreshBar from "../components/RefreshBar";
import OriginalJumpLink from "../components/JumpLink";
import RedirectBar from "../components/RedirectBar";
import logo from "../img/interact-logo.svg";
import classNames from "classnames";

const enhance = compose(
  withFirebase,
  withRouter,
  connect(
    ({ firebase }) => ({
      auth: firebase.auth,
    }),
    null
  )
);

const SUBNAV_TO_PADDING = 3.25;

class Navbar extends Component {
  componentDidMount() {
    this.onResize();
    document.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  }

  constructor() {
    super();
    this.state = {
      isOpen: false,
      isPinned: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.scrollToLink = this.scrollToLink.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  onScroll() {
    const { isPinned } = this.state;
    const { subNav } = this.props;
    const shouldPin = window.scrollY > this.rem * SUBNAV_TO_PADDING;
    if (subNav && shouldPin !== isPinned) {
      this.setState({ isPinned: shouldPin });
    }
  }

  onResize() {
    this.rem = parseInt(window.getComputedStyle(document.body).fontSize);
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  scrollToLink(href) {
    return (e) => {
      e && e.preventDefault();

      const [path, hash] = href.split("#");
      if (!path || !hash) return;

      if (window.location.pathname !== path) {
        this.props.history.push(path);
        setTimeout(() => {
          this.scrollToLink(href)();
        }, 500);
        return;
      }

      const elem = document.getElementById(hash);
      console.log(elem);
      if (!elem) {
        return;
      }

      const offset = elem.offsetTop;
      scrollTo(offset, 500, null);
      if (this.state.isOpen) {
        this.toggleOpen();
      }
    };
  }

  render() {
    const { auth, location, subNav, loggedOut } = this.props;
    const { isOpen, isPinned } = this.state;
    const JumpLink = (props) => (
      <OriginalJumpLink
        {...props}
        onJump={() => {
          if (this.state.isOpen) {
            this.toggleOpen();
          }
        }}
      />
    );

    const className = classNames(
      "Navbar",
      { "nav-open": isOpen },
      { "is-pinned": isPinned },
      { "logged-out": loggedOut },
      { "with-subnav": subNav }
    );

    return (
      <div className={className}>
        <RefreshBar refreshing={location.pathname === "/refresh"} />
        <RedirectBar redirecting={location.pathname === "/url"} />
        <div className="dark-bg m-hidden"></div>
        <div className="content">
          <ul className="nav-items">
            <li className="interact-logo">
              {loggedOut && (
                <a href="https://joininteract.com/?anim=off">
                  <img src={logo} alt="Interact Logo" />
                </a>
              )}
              {!loggedOut && (
                <Link to="/home">
                  <img src={logo} alt="Interact Logo" />
                </Link>
              )}
            </li>
            <li className="spacer"></li>
            <li className="hamburger" onClick={this.toggleOpen}>
              <div className="ham"></div>
              <div className="bur"></div>
              <div className="gur"></div>
            </li>
            <li className="drawer">
              <ul className="drawer-items">
                {!loggedOut && subNav === "guide" ? (
                  <>
                    <li className="m-only">
                      <JumpLink href="/guide#what-is-interact">
                        What is Interact
                      </JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/guide#expectations">
                        Expectations
                      </JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/guide#schedule">Schedule</JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/guide#programs-offerings">
                        Programs &amp; Offerings
                      </JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/guide#contact">Contact</JumpLink>
                    </li>
                    <li className="m-only divider"></li>
                  </>
                ) : (
                  <>
                    <li className="m-only">
                      <JumpLink href="/home#members">Members</JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/home#events">Events</JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/home#newsletters">News</JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/home#resources">Resources</JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/home#venture">VC Partners</JumpLink>
                    </li>
                    <li className="m-only">
                      <JumpLink href="/home#team">Team</JumpLink>
                    </li>
                    <li className="m-only divider"></li>
                  </>
                )}
                {loggedOut && (
                  <>
                    <li>
                      <a href="http://joininteract.com/fellowship">Apply</a>
                    </li>
                    <li>
                      <a href="http://joininteract.com/fellowship">
                        Fellowship
                      </a>
                    </li>
                  </>
                )}
                {!loggedOut && (
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://go.joininteract.com/wiki"
                    >
                      Wiki
                    </a>
                  </li>
                )}
                {!loggedOut && (
                  <li>
                    <Link to="/profile">Edit Profile</Link>
                  </li>
                )}
                {loggedOut && (
                  <li>
                    <a href="/">Login</a>
                  </li>
                )}
                {!loggedOut && (
                  <li>
                    <Link to="/logout">
                      Sign Out
                      <span className="m-only light">&mdash; {auth.email}</span>
                      <span className="m-hidden email">
                        Logged in as {auth.email}
                      </span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
        {subNav === "home" && (
          <div className="content">
            <ul className="sub-nav">
              <li>
                <JumpLink href="/home#members">Members</JumpLink>
              </li>
              <li>
                <JumpLink href="/home#events">Events</JumpLink>
              </li>
              <li>
                <JumpLink href="/home#newsletters">News</JumpLink>
              </li>
              <li>
                <JumpLink href="/home#resources">Resources</JumpLink>
              </li>
              <li>
                <JumpLink href="/home#venture">VC Partners</JumpLink>
              </li>
              <li>
                <JumpLink href="/home#team">Team</JumpLink>
              </li>
            </ul>
          </div>
        )}
        {subNav === "guide" && (
          <div className="content">
            <ul className="sub-nav">
              <li>
                <JumpLink href="/guide#what-is-interact">
                  What is Interact
                </JumpLink>
              </li>
              <li>
                <JumpLink href="/guide#expectations">Expectations</JumpLink>
              </li>
              <li>
                <JumpLink href="/guide#schedule">Schedule</JumpLink>
              </li>
              <li>
                <JumpLink href="/guide#programs-offerings">
                  Programs &amp; Offerings
                </JumpLink>
              </li>
              <li>
                <JumpLink href="/guide#contact">Contact</JumpLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}
function _easeInOutQuad(x, t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
  return (-c / 2) * (--t * (t - 2) - 1) + b;
}

const scrollTo = function (offset, scrollDuration, cb) {
  cb = cb || function () {};
  var startT = Date.now();
  var startY = window.scrollY;
  var distanceToTravel = offset - startY;
  var percentComplete = 0;
  var elapsed;
  var scrollToPercent;
  var scrollToY;

  function step() {
    setTimeout(function () {
      if (percentComplete < 1) {
        elapsed = Date.now() - startT;
        percentComplete = elapsed / scrollDuration;
        scrollToPercent = _easeInOutQuad(
          percentComplete,
          elapsed,
          0,
          1,
          scrollDuration
        );
        scrollToY = scrollToPercent * distanceToTravel + startY;
        window.scrollTo(0, scrollToY);
        requestAnimationFrame(step);
      } else {
        window.scrollTo(0, offset);
        cb();
      }
    }, 15);
  }

  step();
};

export default enhance(Navbar);

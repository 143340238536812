import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

class TextField extends React.Component {
  render() {
    const {id, label, type, error, value, tableSchema, onChange, ...otherProps} = this.props;
    const fieldSchema = tableSchema.fieldSchemas[id] || {};
    const hasError = error?.field === id;
    const currentValue = value || '';
    const overLimit = fieldSchema.maxLength && currentValue.length > fieldSchema.maxLength;
    const showCharCount = fieldSchema.maxLength && currentValue.length / fieldSchema.maxLength > 0.5;
    return (
      <div className={`form-control ${(hasError || overLimit) ? 'has-error' : ''}`}>
        {showCharCount && (
          <span className={`char-count ${overLimit ? 'over-limit': ''}`}>({value.length} / {fieldSchema.maxLength})</span>
        )}
        <label htmlFor={id}>{label} {fieldSchema.required ? <span className="required" />: ''}</label>
        {type === 'textarea' ? (
          <TextareaAutosize
            name={id}
            value={currentValue}
            onChange={(e) => onChange(id, e.target.value)}
            {...otherProps}
          />
        ) : (
          <input
            type={type || "text"}
            onChange={(e) => onChange(id, e.target.value)}
            name={id}
            value={currentValue}
            {...otherProps}
          />
        )}
        {hasError && (
          <div className="error">{error.error}</div>
        )}
      </div>
    );
  }
}

export default TextField;

import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

class InteractMap extends Component {
  render() {
    const { members, onMarkerClustererClick } = this.props;
    const membersForMap = [];
    for (const m of members || []) {
      if (!!m.geoLocation && m.geoLocation.length > 0) {
        for (let i = 0; i < m.geoLocation.length; i++) {
          membersForMap.push({
            name: m.fullName,
            geoLocation: m.geoLocation[i].geo,
            cohort: m.cohort,
            location: m.geoLocation[i].city, // -- geoLocation and cohorts are not 1 to 1 mapping
          });
        }
      }
    }
    const googleMapsOptions = {
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
    };

    return (
      <div>
        <GoogleMap
          defaultZoom={8}
          defaultCenter={{ lat: 37.8271784, lng: -122.2913078 }} // SF
          options={googleMapsOptions}
        >
          <MarkerClusterer onClick={onMarkerClustererClick} zoomOnClick={false}>
            {membersForMap.map((member, i) => (
              <Marker
                position={member.geoLocation}
                key={i}
                label={`${member.name} (${member.cohort})`}
                title={member.location}
              />
            ))}
          </MarkerClusterer>
        </GoogleMap>
      </div>
    );
  }
}
export default withScriptjs(withGoogleMap(InteractMap));

import React, {Component} from "react";
import _ from 'underscore';
import { FaTimes } from 'react-icons/fa';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { TABLES, UPDATE_TYPES } from '../../shared/schema';
import { withCurrentMember } from '../../utils';

import TextField from '../fields/TextField';
import MultiSelectField from '../fields/MultiSelectField';

import { RequestState } from '../../constants/enums';
import * as ProjectActions from '../../actions/ProjectActions';
import './EditProjectModal.scss';
import './Modal.scss';

const enhance = compose(
  withCurrentMember,
  firebaseConnect((props) => [
    { path: TABLES.MEMBERS.firebaseRef },
  ]),
  connect(
    ({ firebase, project }) => ({
      members: firebase.data[TABLES.MEMBERS.firebaseRef],
      currentProject: project,
    }),
    (dispatch, ownProps) => ({
      updateField: (fieldName, value) => {
        dispatch(ProjectActions.setProjectField(fieldName, value));
      },
      initializeProject: (memberInfo) => {
        dispatch(ProjectActions.initializeProject(memberInfo));
      },
      setProjectRequestState: (requestState, error) => {
        dispatch(ProjectActions.setProjectRequestState(requestState, error));
      },
    })
  )
);

const noSubmit = (e) => {
  e.preventDefault();
  e.stopPropagation();
}

export class EditProjectModal extends Component {

  constructor(props) {
    super(props);

    props.initializeProject(props.project || {});

    this.updateProject = this.updateProject.bind(this);
    this.createProject = this.createProject.bind(this);
  }

  updateProject(e) {
    e && e.preventDefault();
    const {
      firebase,
      airtableId,
      currentProject,
      setProjectRequestState,
      startClosing,
    } = this.props;

    if (currentProject.requestState === RequestState.UPDATING) {
      return false;
    }

    const updates = _.object(Object.entries(currentProject.fields).filter(([k,v]) => !TABLES.MEMBERS.fieldSchemas[k]?.readOnly));

    const updateProject = firebase.functions().httpsCallable('project-update');
    setProjectRequestState(RequestState.UPDATING, null);
    clearTimeout(this.timeoutId);
    updateProject({
      projectId: currentProject.fields.airtableId,
      fields: {
        member: [airtableId],
        ...updates
      }
    }).then(() => {
        setProjectRequestState(RequestState.UPDATED, null)
        startClosing();
      })
      .catch((error) => {
        console.error("Error sending payload:", updates, error.details)
        setProjectRequestState(RequestState.READY ,{
          message: (
            error.details
              ? 'Some fields have errors. See above for details.'
              : error.statusCode === 403
              ? error.toString()
              : 'An unknown error occurred. Please try again later.'
          ),
          fieldErrors: error.details,
        });
      });
    return false;
  }

  createProject(e) {
    e && e.preventDefault();
    const {
      firebase,
      airtableId,
      currentProject,
      setProjectRequestState,
      startClosing,
    } = this.props;

    if (currentProject.requestState === RequestState.UPDATING) {
      return false;
    }

    const payload = {
      fields: {
        ...currentProject.fields,
        member: [airtableId],
        type: UPDATE_TYPES.PROJECT,
      }
    };

    const createProject = firebase.functions().httpsCallable('project-create');
    setProjectRequestState(RequestState.UPDATING, null);
    clearTimeout(this.timeoutId);
    createProject(payload)
      .then(() => {
        setProjectRequestState(RequestState.UPDATED, null)
        startClosing();
      })
      .catch((error) => {
        console.error("Error sending payload:", payload, error.details)
        setProjectRequestState(RequestState.READY, {
          message: (
            error.details
              ? 'Some fields have errors. See above for details.'
              : error.statusCode === 403
              ? error.toString()
              : 'An unknown error occurred. Please try again later.'
          ),
          fieldErrors: error.details,
        });
      });
    return false;
  }

  renderContent() {
    const { currentProject, updateField } = this.props;
    const project = currentProject.fields;
    const fieldErrors = currentProject.error.fieldErrors
    const isEditing = !!project.airtableId;
    let submitButtonText = '';
    switch (currentProject.requestState) {
      case RequestState.UPDATING:
        submitButtonText = isEditing ? 'Saving...' : 'Adding...';
        break;
      case RequestState.UPDATED:
        submitButtonText = isEditing ? 'Saved ✔' : 'Added ✔';
        break;
      case RequestState.READY:
      default:
        submitButtonText = isEditing ? 'Save' : 'Add';
        break;
    }

    return (
      <div className="EditProjectModal">
        <h2>{ isEditing ? 'Edit Collaboration' : 'Add Collaboration'}</h2>
        <form onClick={noSubmit}>
          <TextField
            id="update"
            value={project.update}
            tableSchema={TABLES.UPDATES}
            label="Update"
            onChange={updateField}
            error={fieldErrors}
            type="textarea"
            placeholder="I built a website that helps people find their lost dogs!" />
          <div className="form-control-set">
            <TextField
              id="date"
              value={project.date}
              tableSchema={TABLES.UPDATES}
              label="Date"
              onChange={updateField}
              error={fieldErrors}
              placeholder="2020-02-15" />
            <TextField
              id="link"
              value={project.link}
              tableSchema={TABLES.UPDATES}
              label="Link"
              onChange={updateField}
              error={fieldErrors}
              placeholder="https://www.mycoolwebsite.com" />
          </div>
          <MultiSelectField
            error={fieldErrors}
            onChange={updateField}
            value={project.collaborators}
            tableSchema={TABLES.UPDATES}
            id="collaborators"
            label="Collaborators"
            placeholder="Add a collaborator..." />
          <div className="form-control-set">
            <div className="form-control small">
            <input
              type="submit"
              disabled={project.requestState === RequestState.UPDATING}
              className="Button green"
              onClick={isEditing ? this.updateProject : this.createProject}
              value={submitButtonText}
            />
            </div>
            {currentProject.error?.message && (
              <div className="form-control">
                <div className="error-message">
                  { currentProject.error.message }
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }

  render() {
    const { startClosing } = this.props;

    return (
      <div className="Modal">
        <div className="modal-scrim" onClick={startClosing}></div>
        <div className="modal-window">
          <div className="top-bar">
            <FaTimes className="close-button" onClick={startClosing} />
          </div>
          <div className="modal-content">
            { this.renderContent() }
          </div>
        </div>
      </div>
    );
  }
}

export default enhance(EditProjectModal);

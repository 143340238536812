export const ModalType = {
  'PARTNER_MODAL': 'PARTNER_MODAL',
  'MEMBER_MODAL': 'MEMBER_MODAL',
  'EDIT_PROJECT_MODAL': 'EDIT_PROJECT_MODAL',
};

export const RequestState = {
  'READY': 0,
  'UPDATING': 1,
  'UPDATED': 2,
};

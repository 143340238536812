// eslint-disable-next-line
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import GoogleButton from "react-google-button";
import "./LoginPage.scss";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LogRocket from "logrocket";

import * as UserActions from "../actions/UserActions";

const enhance = compose(
  withRouter,
  withFirebase,
  connect(
    ({ firebase: { auth }, user: { isWhitelisted, email } }) => ({
      auth,
      isWhitelisted,
      whitelistEmail: email,
    }),
    (dispatch, ownProps) => ({
      updateCustomClaims: () => {
        const { firebase } = ownProps;
        const user = firebase.auth().currentUser;
        if (user) {
          user
            .getIdTokenResult(true)
            .then((idTokenResult) => {
              const isWhitelisted = idTokenResult.claims.isWhitelisted;
              const email = idTokenResult.claims.email;
              window.analytics.identify({
                email: email,
              });
              dispatch(UserActions.updateIsWhitelisted(isWhitelisted, email));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    })
  )
);

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const { updateCustomClaims, history, redirectTo } = props;
    history.replace(redirectTo ? `/?redirectTo=${redirectTo}` : "/");
    updateCustomClaims();
  }

  componentDidMount() {
    window.analytics.page("Login");
  }

  loginWithGoogle() {
    const { updateCustomClaims, firebase } = this.props;
    let callback = null;
    let metadataRef = null;

    firebase.auth().onAuthStateChanged((user) => {
      // Remove previous listener
      if (callback) {
        metadataRef.off("value", callback);
      }
      // On user login add new listener
      if (user) {
        // Note: This is Google Auth's name + email, not Airtable's.
        LogRocket.identify(user.uid, {
          name: user.displayName,
          email: user.email,
        });
        // Check if whitelist refresh is required
        metadataRef = firebase
          .database()
          .ref("metadata/" + user.uid + "/refreshTime");
        callback = (snapshot) => {
          updateCustomClaims();
        };
        metadataRef.on("value", callback);
      }
    });

    return firebase.login({ provider: "google", type: "popup" });
  }

  render() {
    const { auth, isWhitelisted, whitelistEmail } = this.props;
    const googleButton = (
      <GoogleButton type="light" onClick={this.loginWithGoogle.bind(this)} />
    );
    const currentUserIsKnownAsNotWhitelisted =
      isWhitelisted === false && auth.email === whitelistEmail;

    let loginControls;
    if (!isLoaded(auth)) {
      // Auth not loaded yet
      loginControls = <span className="Spinner" />;
    } else if (currentUserIsKnownAsNotWhitelisted) {
      // User known to be not whitelisted
      loginControls = (
        <React.Fragment>
          <p>
            {'"' + auth.email + '"' || "Your email address"} is not on our allow
            list. Please try a different account. If you believe this to be in
            error, contact{" "}
            <a href="mailto:tech-help@joininteract.com">
              tech-help@joininteract.com
            </a>
            .
          </p>
          {googleButton}
        </React.Fragment>
      );
    } else if (!isEmpty(auth) && !currentUserIsKnownAsNotWhitelisted) {
      // User whitelist status is not confirmed
      loginControls = <span className="Spinner" />;
    } else {
      // User not signed in
      loginControls = googleButton;
    }

    return (
      <div className="LoginPage">
        <Navbar loggedOut={true} />
        <div className="text-content">
          <h1>
            Welcome to the <span className="title">Interact Portal</span>
          </h1>
          <div className="login-controls">{loginControls}</div>
          <p className="light">
            <a href="mailto:tech-help@joininteract.com?subject=Login Help">
              Need help?
            </a>
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default enhance(LoginPage);

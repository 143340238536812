import React, { Component } from 'react';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FaNewspaper } from 'react-icons/fa';
import {CLICK_ENTRY, Sections} from '../constants/Analytics';
import IconCard from './IconCard';
import {TABLES} from '../shared/schema';

const enhance = compose(
  firebaseConnect((props) => [
    { path: TABLES.NEWSLETTERS.firebaseRef }
  ]),
  connect(({ firebase }) => ({
    newsletters: firebase.data[TABLES.NEWSLETTERS.firebaseRef],
  }))
);

const quarterSort = (a, b) => {
  const [aQuarter, aYear] = a.quarter.split(' ');
  const [bQuarter, bYear] = b.quarter.split(' ');
  if (aYear === bYear) {
    return bQuarter.localeCompare(aQuarter);
  } else {
    return bYear.localeCompare(aYear);
  }
}

class NewsletterList extends Component {

  render() {
    const newsletters = Object.values(this.props.newsletters || {});

    if (!isLoaded(newsletters)) {
      return (
        <div>
          <div className="placeholder">
            <span className="Spinner dark"/>
          </div>
        </div>
      );
    }

    const newsletterList = newsletters && newsletters
      .sort(quarterSort)
      .map((newsletter, idx) => (
        <IconCard key={idx}
                  Icon={FaNewspaper}
                  title={newsletter.quarter}
                  href={newsletter.link}
                  onClick={function() {
                    window.analytics.track(CLICK_ENTRY, {
                      section: Sections.NEWSLETTERS,
                      item: newsletter,
                    });
                  }}
                  imageUrl={newsletter.coverImage} />
      ));

    return (
      <div className="cardlist">
        {newsletterList}
      </div>
    );
  }
}

export default enhance(NewsletterList);

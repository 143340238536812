import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Button from '../components/Button';
import ModalManager from '../components/modals/ModalManager';
import ProfileOnboardingJourney from '../components/ProfileOnboardingJourney';
import ProfilePictureEditor from '../components/ProfilePictureEditor';
import TextField from '../components/fields/TextField';
import MultiSelectField from '../components/fields/MultiSelectField';
import CheckboxGroup from '../components/fields/CheckboxGroup';
import MemberUpdateList from '../components/MemberUpdateList';
import * as ProfileActions from '../actions/ProfileActions';
import { RequestState } from '../constants/enums';
import { FaEye } from 'react-icons/fa';
import { TABLES } from '../shared/schema';
import _ from 'underscore';
import { withCurrentMember } from '../utils';

import './ProfilePage.scss';

const enhance = compose(
  withRouter,
  withCurrentMember,
  connect(
    ({ profile, memberInfo }) => ({
      profile,
      memberInfo,
    }),
    (dispatch, ownProps) => ({
      updateField: (fieldName, value) => {
        dispatch(ProfileActions.setProfileField(fieldName, value));
      },
      initializeProfile: (memberInfo) => {
        dispatch(ProfileActions.initializeProfile(memberInfo));
      },
      requestStarted: () => {
        dispatch(ProfileActions.setProfileRequestState(RequestState.UPDATING, null));
      },
      requestCompletedSuccessfully: () => {
        dispatch(ProfileActions.setProfileRequestState(RequestState.UPDATED, null));
      },
      requestCompletedWithErrors: (errorPayload) => {
        dispatch(ProfileActions.setProfileRequestState(RequestState.READY, errorPayload));
      },
      resetRequestState: () => {
        dispatch(ProfileActions.setProfileRequestState(RequestState.READY));
      },
    })
  )
);

class ProfilePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updateOnNextProps: false,
    };
    this.updateFieldAndProfile = this.updateFieldAndProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.onAddProject = this.onAddProject.bind(this);
    this.timeoutId = 0;
  }

  componentDidMount() {
    window.analytics.page('ProfilePage');
    window.scrollTo({top: 0});
  }

  componentDidUpdate(prevProps, prevState) {
    const { initializeProfile, currentMember } = this.props;
    if (prevProps.currentMember !== currentMember) {
      initializeProfile(currentMember);
    } else if (prevState.updateOnNextProps) {
      this.setState({
        updateOnNextProps: false,
      }, this.updateProfile);
    }
  }

  updateProfile(e) {
    e && e.preventDefault();
    const {
      firebase,
      profile,
      airtableId,
      requestStarted,
      requestCompletedSuccessfully,
      requestCompletedWithErrors,
      resetRequestState,
    } = this.props;

    if (profile.requestState === RequestState.UPDATING) {
      return false;
    }

    const updates = _.object(Object.entries(profile.fields).filter(([k,v]) => !TABLES.MEMBERS.fieldSchemas[k]?.readOnly));

    const updateProfile = firebase.functions().httpsCallable('updateProfile-updateProfile');
    requestStarted();
    clearTimeout(this.timeoutId);
    updateProfile({ airtableId: airtableId, fields: updates })
      .then(() => requestCompletedSuccessfully())
      .then(() => setTimeout(resetRequestState, 3000))
      .catch((error) => {
        console.error("Error sending payload:", updates)
        requestCompletedWithErrors({
          message: (
            error.details
              ? 'Some fields have errors. See above for details.'
              : error.statusCode === 403
              ? error.toString()
              : 'An unknown error occurred. Please try again later.'
          ),
          fieldErrors: error.details,
        });
      });
    return false;
  }

  updateFieldAndProfile(fieldName, value) {
    const { updateField } = this.props;
    this.setState({
      updateOnNextProps: true,
    }, () => updateField(fieldName, value));
  }

  onAddProject(projectId) {
    const { profile } = this.props;
    this.updateFieldAndProfile('communityUpdates', [...profile.communityUpdates, projectId])
  }

  renderContent() {
  const { updateField, profile } = this.props;
    const fields = profile.fields;

    let submitButtonText = 'Update';
    switch (profile.requestState) {
      case RequestState.UPDATING:
        submitButtonText = 'Updating...';
        break;
      case RequestState.UPDATED:
        submitButtonText = 'Updated ✔';
        break;
      default:
        break;
    }

    if (!profile.isLoaded) {
      return (
        <div className="content">
          <h2>Edit Profile</h2>
          <div className="placeholder">
            <span className="Spinner dark" />
          </div>
        </div>
      );
    }

    return (
      <div className="content">
        <h2>
          Edit Profile{" "}
          <Button color="hidden-text green-text" to={`/member/${fields.slug}`}>
            <FaEye /> <span>Visit Live</span>
          </Button>
        </h2>
        <ProfileOnboardingJourney />
        <div className="form-wrapper">
          <ProfilePictureEditor value={fields.linkToHeadshot} />
          <form onSubmit={this.updateProfile}>
            <div className="form-control-set">
              <TextField
                id="firstName"
                value={fields.firstName}
                tableSchema={TABLES.MEMBERS}
                label="First Name"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="Maran"
              />
              <TextField
                id="lastName"
                value={fields.lastName}
                tableSchema={TABLES.MEMBERS}
                label="Last Name"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="Nelson"
              />
              <TextField
                id="namePronunciation"
                value={fields.namePronunciation}
                tableSchema={TABLES.MEMBERS}
                label="Pronunciation"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="mare-un nell-son"
              />
            </div>
            <div className="form-control-set">
              <MultiSelectField
                id="pronouns"
                value={fields.pronouns}
                tableSchema={TABLES.MEMBERS}
                label="Pronouns"
                onChange={this.updateFieldAndProfile}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="Add pronouns..."
              />
              <div className="form-control small">
                <label>Cohort</label>
                <p>{fields.cohort}</p>
              </div>
            </div>
            <TextField
              id="firstPersonIntro"
              value={fields.firstPersonIntro}
              tableSchema={TABLES.MEMBERS}
              label="First-person intro"
              onChange={updateField}
              error={profile.error?.fieldErrors}
              onBlur={this.updateProfile}
              type="textarea"
              placeholder="Hi there! I'm super down to meet up and love Interact!"
            />
            <TextField
              id="currentCompanyAndRole"
              value={fields.currentCompanyAndRole}
              tableSchema={TABLES.MEMBERS}
              label="Current Company and Role"
              onChange={updateField}
              error={profile.error?.fieldErrors}
              onBlur={this.updateProfile}
              placeholder="Software Engineer at Twitter"
            />
            <MultiSelectField
              error={profile.error?.fieldErrors}
              onChange={this.updateFieldAndProfile}
              value={fields.currentLocation}
              tableSchema={TABLES.MEMBERS}
              id="currentLocation"
              label="Location"
              placeholder="Add a location..."
            />

            <h3>Interests and Skills</h3>
            <MultiSelectField
              error={profile.error?.fieldErrors}
              onChange={this.updateFieldAndProfile}
              value={fields.interests}
              tableSchema={TABLES.MEMBERS}
              id="interests"
              label="Interests: Things you are interested in learning about"
              placeholder="Add an interest..."
            />

            <MultiSelectField
              error={profile.error?.fieldErrors}
              onChange={this.updateFieldAndProfile}
              value={fields.skills}
              tableSchema={TABLES.MEMBERS}
              id="skills"
              label="Skills: Things you could help someone else learn about"
              placeholder="Add an skill..."
            />

            <h3>Updates</h3>
            <MemberUpdateList
              member={profile.fields}
              editable={true}
              onCreate={this.onAddProject}
            />

            <h3>Contact Info</h3>
            {fields.contactPreferences?.length > 0 ? (
              <p className="small">
                This information will be displayed on your profile.
              </p>
            ) : (
              <p className="small">
                This information is hidden from your profile.
              </p>
            )}
            <div className="form-control-set">
              <TextField
                id="emailAddress"
                value={fields.emailAddress}
                tableSchema={TABLES.MEMBERS}
                label="Email Address"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="you@yourname.com"
                type="email"
              />
              <TextField
                id="twitterUsername"
                value={fields.twitterUsername}
                tableSchema={TABLES.MEMBERS}
                label="Twitter Handle"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="@yourhandle"
              />
            </div>
            <div className="form-control-set">
              <TextField
                id="facebookProfileUrl"
                value={fields.facebookProfileUrl}
                tableSchema={TABLES.MEMBERS}
                label="Facebook Profile URL"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="https://www.facebook.com/yourhandle"
              />
              <TextField
                id="linkedinProfileUrl"
                value={fields.linkedinProfileUrl}
                tableSchema={TABLES.MEMBERS}
                label="LinkedIn Profile URL"
                onChange={updateField}
                error={profile.error?.fieldErrors}
                onBlur={this.updateProfile}
                placeholder="https://www.linkedin.com/in/yourhandle"
              />
            </div>

            <h3>Contact Preferences</h3>
            <CheckboxGroup
              fieldId="contactPreferences"
              title="Allow Interact Members to contact me"
              description="Deselecting will hide your contact info and prompt members to request a warm intro from the Interact team."
              checkedOptions={fields.contactPreferences}
              onChange={this.updateFieldAndProfile}
              options={TABLES.MEMBERS.fieldSchemas.contactPreferences.options}
            />
            <div className="form-control-set">
              <div className="form-control small">
                <input
                  type="submit"
                  disabled={profile.requestState === RequestState.UPDATING}
                  className="Button green"
                  value={submitButtonText}
                />
              </div>
              {profile.error?.message && (
                <div className="form-control">
                  <div className="error-message">{profile.error.message}</div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="ProfilePage">
        <Navbar />
        <section id="members">
          { this.renderContent() }
        </section>
        <Footer />
        <ModalManager />
      </div>
    );
  }

}

export default enhance(ProfilePage);

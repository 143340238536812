import React, { Component } from "react";

import "./RefreshBar.scss";

class RefreshBar extends Component {
  render() {
    const { refreshing } = this.props;

    return (
      <div className={`RefreshBar ${refreshing ? "refreshing" : ""}`}>
        <div className="Spinner" /> Refreshing Airtable
      </div>
    );
  }
}

export default RefreshBar;

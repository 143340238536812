import React from 'react';
import CheckboxField from './CheckboxField';
import { FaRegSquare, FaCheckSquare, FaMinusSquare } from 'react-icons/fa';

const CHECK_STATE = {
  SOME: 'some',
  ALL: 'all',
  NONE: 'none',
}


class CheckboxGroup extends React.Component {

  constructor(props) {
    super(props);
    this.onCheckChild = this.onCheckChild.bind(this);
  }

  _getCheckState() {
    const {checkedOptions, options} = this.props;
    if (options && checkedOptions) {
      if (options.every(opt => checkedOptions.indexOf(opt.id) >= 0)) {
        return CHECK_STATE.ALL;
      } else if (options.some(opt => checkedOptions.indexOf(opt.id) >= 0)) {
        return CHECK_STATE.SOME;
      }
    }
    return CHECK_STATE.NONE;
  }

  renderSummaryCheckbox(checkState) {
    switch (checkState) {
      case CHECK_STATE.ALL:
        return <FaCheckSquare />;
      case CHECK_STATE.SOME:
        return <FaMinusSquare />;
      case CHECK_STATE.NONE:
        return <FaRegSquare />;
      default:
        return <FaRegSquare />;
    }
  }

  onCheckChild(id, isChecked) {
    const { fieldId, onChange, checkedOptions } = this.props;
    let currentlyChecked = new Set(checkedOptions);
    if (isChecked) {
      currentlyChecked.add(id);
    } else {
      currentlyChecked.delete(id);
    }
    if (currentlyChecked.length === 0) {
      onChange(fieldId, null);
    } else {
      onChange(fieldId, Array.from(currentlyChecked));
    }
  }

  onCheckParent(isChecked) {
    const { fieldId, onChange, options } = this.props;
    if (isChecked) {
      onChange(fieldId, options.map(opt => opt.id));
    } else {
      onChange(fieldId, null);
    }
  }

  render() {
    const {title, description, fieldId, label, tableSchema, checkedOptions, options, error} = this.props;
    const hasError = error?.field === fieldId;
    const checkState = this._getCheckState();
    return (
      <div className={`form-control checkbox ${hasError ? 'has-error' : ''}`}>
        {label && <label>{label} {tableSchema.fieldSchemas[fieldId]?.required ? <span className="required" />: ''}</label>}
        <label htmlFor={fieldId}>
          { this.renderSummaryCheckbox(checkState) }
          <span className="text">
            <span className="title">{title}</span>
            <span className="description">{description}</span>
          </span>
        </label>
        <input
          type="checkbox"
          onChange={(e) => this.onCheckParent(e.target.checked)}
          name={fieldId}
          id={fieldId}
          checked={checkState !== CHECK_STATE.NONE}
        />
        <ul className="checkbox-options">
          {options.map(({id, title, description}, idx) =>(
            <li className="checkbox-option" key={idx}>
              <CheckboxField
                id={id}
                title={title}
                isChecked={checkedOptions ? (checkedOptions.indexOf(id) >= 0) : false}
                description={description}
                onChange={this.onCheckChild}
                />
            </li>
          ))}
        </ul>
        {hasError && (
          <div className="error">{error.error}</div>
        )}
      </div>
    );
  }
}

export default CheckboxGroup;

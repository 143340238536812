import * as ActionTypes from '../constants/ActionTypes';

export const initializeProfile = (fields) => ({
  type: ActionTypes.PROFILE_INITIALIZE_PROFILE,
  fields,
});

export const setProfileField = (fieldName, value) => ({
  type: ActionTypes.PROFILE_SET_FIELD,
  fieldName,
  value,
});

export const setProfileRequestState = (requestState, error) => ({
  type: ActionTypes.PROFILE_SET_REQUEST_STATE,
  requestState,
  error
});

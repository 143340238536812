import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import './index.scss';

class IconCard extends Component {
  render() {
    const { Icon, title, href, date, imageUrl, onClick } = this.props;
    const dt = new Date(date)
    return (
      <div className="IconCard cardlist-item">
        <a className="inner"
          href={ href }
          target="_blank"
          onClick={ onClick }
          rel="noopener noreferrer"
        >
          <i className="image" style={{
            'backgroundImage': 'url(' + imageUrl + ')',
          }}>
            {
              moment(dt).isValid()
              ? <span className="date">
                  <Moment date={dt} format="M/D/YY" tz="gmt"/>
                </span>
              : <span></span>
            }
          </i>
          <div className="text">
            <Icon color="#59b26f" />
            <h4 className="name">{ title }</h4>
          </div>
        </a>
      </div>
    );
  }
}

export default IconCard;

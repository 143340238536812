import React, { PureComponent } from 'react';
import { withFirebase } from 'react-redux-firebase';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import './Footer.scss';

const enhance = compose(
  withFirebase,
  withRouter,
);

class Footer extends PureComponent {
  render() {
    const loggedOut = (window.location.pathname === '/');
    const year = new Date().getFullYear();
    return (
      <div className="Footer">
        <div className="content">
          <div className="grid">
            <div className="half">
              <p>&copy; {year} Interact ATX</p>
            </div>
            <div className="half grid m-grid">
                <ul className="nav-items social half">
                    <li><a href="mailto:team@joininteract.com">Email</a></li>
                    <li><a href="https://twitter.com/joininteract">Twitter</a></li>
                    <li><a href="https://medium.com/joininteract">Medium</a></li>
                </ul>
                <ul className="nav-items half">
                    {/*<li><a className="application-link disabled">Apply</a></li>*/}
                    <li><a href="http://joininteract.com/fellowship">Fellowship</a></li>
                    <li><a href="https://medium.com/joininteract">Blog</a></li>
                    {loggedOut && <li><a href="/">Login</a></li>}
                    {!loggedOut && <li><Link to="/logout">Sign Out</Link></li>}
                </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default enhance(Footer);

import * as ActionTypes from '../constants/ActionTypes';

export const updateIsWhitelisted = (isWhitelisted, email) => ({
  type: ActionTypes.USER_UPDATE_IS_WHITELISTED,
  isWhitelisted,
  email,
});

export const logout = () => ({
  type: ActionTypes.USER_LOGOUT,
  isWhitelisted: null,
  email: null,
});

import { TABLES } from './shared/schema';
import { RequestState } from './constants/enums';
import _  from 'underscore';

export const user = {
  isWhitelisted: null,
  email: null,
};

export const modal = null;

export const profile = {
  fields: _.object(
    Object.entries(TABLES.MEMBERS.firebaseToAirtable)
    .map(([field, _]) => ([field, null]))),
  isLoaded: false,
  requestState: RequestState.READY,
  error: {
    message: null,
    fieldErrors: null,
  },
};

export const project = {
  fields: _.object(
    Object.entries(TABLES.UPDATES.firebaseToAirtable)
    .map(([field, _]) => ([field, null]))),
  isLoaded: false,
  requestState: RequestState.READY,
  error: {
    message: null,
    fieldErrors: null,
  },
};

const initialState = {
  user,
  modal,
  profile,
  project,
}

export default initialState;

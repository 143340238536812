import { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";
import { compose } from "redux";

const enhance = compose(withRouter, withFirebase);

class RefreshPage extends Component {
  componentDidMount() {
    const { history, firebase } = this.props;

    const sync = firebase
      .functions()
      .httpsCallable("syncAirtableToRTDB-manuallySyncAirtableToRTDB");
    sync()
      .then((result) => {
        history.replace("/home");
      })
      .catch((error) => {
        console.error(error);
        history.replace("/home");
      });
  }

  render() {
    return null;
  }
}

export default enhance(RefreshPage);

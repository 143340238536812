import * as types from '../constants/ActionTypes';
import { modal as initialState } from '../initialState';

export default function modal(state = initialState, action) {
  switch (action.type) {
    case types.MODAL_OPEN:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
        context: action.context,
      };
    case types.MODAL_CLOSE:
      return null;
    default:
      return state;
  }
}

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";

import {
  normalizeMembersList,
  renderLinks,
  renderHeadshot,
} from "./MemberList";
import { Sections, CLICK_ENTRY } from "../constants/Analytics";
import FilterableTable from "./FilterableTable";
import TableColumn from "./FilterableTable/TableColumn";
import { TABLES } from "../shared/schema";

import "./TeamList.scss";

const enhance = compose(
  withRouter,
  firebaseConnect((props) => [{ path: TABLES.TEAM.firebaseRef }]),
  connect(({ firebase }) => ({
    team: firebase.data[TABLES.TEAM.firebaseRef],
  }))
);

class TeamList extends Component {
  constructor(props) {
    super(props);
    this.getOnClickRow = this.getOnClickRow.bind(this);
  }

  getOnClickRow(row) {
    const { history } = this.props;
    return (e) => {
      window.analytics.track(CLICK_ENTRY, {
        section: Sections.TEAM,
        item: row,
      });
      history.push(`/member/${row.slug}`);
    };
  }

  render() {
    const team = Object.values(this.props.team || {});
    const teamRows = normalizeMembersList(team);
    teamRows.sort((a, b) => a.team.localeCompare(b.team));
    return (
      <FilterableTable rows={teamRows} getOnClickRow={this.getOnClickRow}>
        <TableColumn
          cid="name"
          title="Name"
          getContent={(m) => (
            <React.Fragment>
              {renderHeadshot(m)}
              <span className="name-and-role">
                <span className="m-name">{m.fullName}</span>
                <span className="m-role">{m.current}</span>
              </span>
            </React.Fragment>
          )}
        />
        <TableColumn
          cid="location"
          title="Location"
          getContent={(m) => (
            <React.Fragment>
              {m.location ? m.location.join(", ") : <span className="none" />}
            </React.Fragment>
          )}
        />
        <TableColumn cid="team" title="Team" />
        <TableColumn cid="role" title="Role" />
        <TableColumn
          cid="links"
          title="Links"
          getContent={(m) => renderLinks(m, Sections.TEAM)}
        />
      </FilterableTable>
    );
  }
}

export default enhance(TeamList);

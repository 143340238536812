import * as types from '../constants/ActionTypes';
import { user as initialState } from '../initialState';

export default function user(state = initialState, action) {
  switch (action.type) {
    case types.USER_UPDATE_IS_WHITELISTED:
      return {
        isWhitelisted: action.isWhitelisted,
        email: action.email,
      };
    default:
      return state;
  }
}

import React, { Component } from "react";
import Navbar from "../components/Navbar";
import TeamList from "../components/TeamList";
import OriginalJumpLink from "../components/JumpLink";
import Footer from "../components/Footer";

import "./GuidePage.scss";

const JumpLink = (props) => <OriginalJumpLink {...props} offset={120} />;

const Header = () => (
  <tr>
    <th></th>
    {[
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ].map((m) => (
      <th>{m}</th>
    ))}
  </tr>
);

const Row = ({ title, months = [] }) => (
  <tr>
    <td>
      <strong>{title}</strong>
    </td>
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) =>
      months.indexOf(m) >= 0 ? <td className="selected"></td> : <td></td>
    )}
  </tr>
);

class GuidePage extends Component {
  componentDidMount() {
    window.analytics.page("GuidePage");
    window.scrollTo({ top: 0 });
  }

  render() {
    return (
      <div className="GuidePage">
        <Navbar subNav="guide" />
        <section>
          <div className="content">
            <p>
              Hello there! This page is out of date, and we're working on making
              a brand new{" "}
              <a
                href="https://go.joininteract.com/wiki"
                rel="noopener noreferrer"
                target="_blank"
              >
                Interact Wiki
              </a>
              , but it is still under construction!
            </p>
            <p>
              If you want access to the Wiki in-progress, post in{" "}
              <a href="https://app.slack.com/client/T0KBNV3SA/C02JCA6FRPC">
                proj-wiki
              </a>{" "}
              on <a href="https://joininteract.slack.com">Interact's Slack</a>!
            </p>
            <hr />
            <p>
              This is a guide to Interact’s history, values and current
              programming. Everything in this guide is available to all Interact
              community members, whether you first came to a retreat in 2013 or
              have just been accepted as a Fellow. Everything you see here was
              co-created by Interacters, whose thousands of collective volunteer
              hours helped this community flourish.{" "}
              <span id="what-is-interact">We</span> can’t wait to see what we
              create together as Interact continues to evolve.
            </p>
            <h2>What is Interact?</h2>
            <p>
              Interact is a 501(c)3 nonprofit that fosters a global, lifelong
              community of mission-driven technologists. Interact creates spaces
              for technologists to engage in inquiry, collaboration, and
              introspection. We share an enthusiasm for making intentional
              impact and find joy in thoughtful conversations. The ultimate goal
              of Interact as an organization is to support the community to
              realize its collective potential in the most meaningful, globally
              useful way possible.
            </p>
            <p>
              “Interact” is both the name of the Fellowship program and the
              community of builders and thinkers who have participated in the
              Fellowship. You are “in Interact” whether you are a first-year
              Fellow who is participating in your first cohort of Circles, or
              whether you attended a retreat seven years ago but still keep in
              touch with people in the community.
            </p>
            <h3 id="a-brief-history-of-interact">
              A Brief History of Interact
            </h3>
            <p>
              Interact was founded in 2012 to answer the question: what would
              happen if high-potential, young technologists—the types of people
              who might work on successful projects and meet later in life—were
              brought together while still in college? Interact’s first 100
              Fellows crashed on couches and gathered in shared homes at South
              by Southwest to hang out and explore ideas together.
            </p>
            <p>
              Each subsequent year, Interacters volunteered hundreds of hours to
              bring in 50-100 new Fellows, plan unique retreat experiences, and
              co-create community together. Over time, the admission process
              evolved from ad-hoc to sophisticated, always with the support of
              Interact community members’ referrals, enabling us to bring
              together even more impressive builders and thinkers. Applicants
              are not selected for their achievements, nor do they need to apply
              with a particular project or company; they are chosen because of
              who they are and the ethic and values they embody.
            </p>
            <p>
              Today, Interact operates a much larger number of programs. In
              addition to yearly retreats, Interact offers spaces for
              collaborative projects (through the Circles program), support for
              founders (through Founder Focus Group and Venture Partner Office
              Hours), regular city meetups, and in-person and remote discussion
              forums.
            </p>
            <p>
              Over the past 9 years, Interacters have built friendships,
              co-founded ventures, and supported one another in achieving their
              missions. Many of us hope to remain in dialogue, empathizing with
              and challenging each other over our lifetimes. While much has
              changed, one thing remains constant: our earnest commitment to
              make our world better.
            </p>
            <h3 id="the-interact-community">The Interact Community</h3>
            <p>
              The Interact community is currently made up of 646 people. Half of
              the community was born outside the United States, while at any
              given moment, around ⅓ live outside the US. A large fraction of
              the community lives in San Francisco, New York, Boston, Los
              Angeles, and London, but we also have strong representation in
              Toronto, Taipei, Lagos, Dublin, Seattle, Oxford, DC, and Hong
              Kong. That being said, you can almost always find an Interact
              member in most major cities on every (inhabited) continent around
              the world.
            </p>
            <p>
              Interact takes great pride in being a diverse and pluralistic
              community. We believe that great things happen when people from
              all backgrounds and interests are empowered and able to support
              and challenge each other. In terms of specific demographic
              statistics: the community as a whole is 59% men, 39% women, and 2%
              nonbinary, though more recent cohorts have had a more equitable
              gender split. 25% of each cohort is made up of under-represented
              minorities in tech (e.g. Black/African American, Hispanic/Latino).
            </p>
            <p>
              Fellows work across too many fields to count: machine learning,
              journalism, cryptocurrencies, tech policy, XR technologies,
              hardware, software engineering, education, biotech, medicine,
              investing, physics, graphic design, product development, UX/UI
              design, visual arts, music, and more. Interacters have
              collectively founded 114 companies to date.
            </p>
          </div>
        </section>
        <section id="expectations">
          <div className="content">
            <h2>Interact Member Expectations</h2>
            <h3 id="interact-values">Interact Values</h3>
            <p>
              As members of Interact, we collectively create and iterate on the
              values that we want the Interact community to represent. These
              values create a framework for admission into Interact, and
              establish a standard of behavior we seek to hold ourselves and
              other community members accountable to.
            </p>
            <p>Interact operates on six core values:</p>
            <ul>
              <li>
                <p>
                  <strong>Mission-Driven</strong> - We feel a deep obligation to
                  improve ourselves, and the lives of others.
                </p>
              </li>
              <li>
                <p>
                  <strong>Discerning</strong> - We seek to be discerning and
                  thoughtful in our opinions.
                </p>
              </li>
              <li>
                <p>
                  <strong>Proficient</strong> - We equip ourselves with the
                  skill-sets necessary to effect meaningful change.
                </p>
              </li>
              <li>
                <p>
                  <strong>Empathic</strong> - We try to put ourselves in the
                  shoes of the person we are communicating with. We seek to
                  reason about and discuss abstract, vulnerable, or otherwise
                  difficult ideas in ways that our peers can learn from.
                </p>
              </li>
              <li>
                <p>
                  <strong>Individualistic</strong> - We try to do what we
                  believe is right, even if others insult or demean us for it.
                </p>
              </li>
              <li>
                <p>
                  <strong>Emotional Integrity</strong> - We are honest and are
                  willing to be found wrong in the face of new perspectives or
                  information.
                </p>
              </li>
            </ul>
            <h3 id="violation-of-values-guidelines">
              Violation of Values &amp; Guidelines
            </h3>
            <p>
              If the Interact team learns that a member repeatedly displays
              behavior that is misaligned with these principles and/or harmful
              to the community, the team reserves the right to revoke that
              individual’s membership temporarily or permanently.
            </p>
            <h4 id="repeated-behavior-reports">Repeated Behavior Reports</h4>
            <p>
              If someone in the Interact community is not living up to these
              values, please do not hesitate to contact anyone on the Interact
              team. You should always feel free to reach out to Matthew Jordan (
              <a href="mailto:matthew@joininteract.com">
                matthew@joininteract.com
              </a>
              ) or Santi Ruiz (
              <a href="mailto:santi@joininteract.com">santi@joininteract.com</a>
              ) personally. For each misconduct report, the Interact team will
              investigate the actions of the member being reported in order to
              develop a better understanding of the situation. After three
              reports from the community about behavior from a member that goes
              against the core values of Interact, the Interact team will take
              into consideration all three investigations. If the misconduct
              reported aligns with the learnings from the investigation, the
              person’s Interact membership will be revoked.
            </p>
          </div>
        </section>
        <section id="schedule">
          <div className="content">
            <h2>2022 Schedule </h2>
            <p>
              Interact runs on a three-term schedule. If you are unable to join
              a program in the first session, there will always be more chances
              for you to engage.
            </p>
            <p>Here is a rough calendar of events for 2022.</p>

            <div className="table-wrapper">
              <table>
                <thead>
                  <Header />
                </thead>
                <tbody>
                  <Row
                    title="Founder Focus Group"
                    months={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                  />
                  <Row
                    title="Circles"
                    months={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                  />
                  <Row title="Venture Office Hours" months={[4, 8, 11]} />
                  <Row title="Course" months={[6, 7, 8]} />
                  <Row title="Fellowship Retreat" months={[6]} />
                  <Row title="Community Retreat" months={[10]} />
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section id="programs-offerings">
          <div className="content">
            <h2 id="programs-offerings">Programs &amp; Offerings</h2>
            <p className="jump-to">
              <em>Jump to:</em>
            </p>
            <div className="jumplinks">
              <div className="jumplink-set">
                <p className="jumplink-title">Core Fellowship Experience</p>
                <ul>
                  <li>
                    <JumpLink href="#fellowship-retreat">
                      Fellowship Retreat
                    </JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#core-course">Core Course</JumpLink>
                  </li>
                </ul>
              </div>
              <div className="jumplink-set">
                <p className="jumplink-title">Programs</p>
                <ul>
                  <li>
                    <JumpLink href="#circles">Circles</JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#founder-focus-group">
                      Founder Focus Group
                    </JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#office-hours">Office Hours</JumpLink>
                  </li>
                </ul>
              </div>
              <div className="jumplink-set">
                <p className="jumplink-title">Community Gatherings</p>
                <ul>
                  <li>
                    <JumpLink href="#community-retreat">
                      Community Retreat
                    </JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#in-person-city-gatherings">
                      In-Person City Gatherings
                    </JumpLink>
                  </li>
                </ul>
              </div>
              <div className="jumplink-set">
                <p className="jumplink-title">Community Engagement Tools</p>
                <ul>
                  <li>
                    <JumpLink href="#member-portal">Member Portal</JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#cohort-wide-listserv">
                      Cohort-Wide Listserv
                    </JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#community-newsletter">
                      Community Newsletter
                    </JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#interact-calendar">
                      Interact Calendar
                    </JumpLink>
                  </li>
                  <li>
                    <JumpLink href="#townhalls">Townhalls</JumpLink>
                  </li>
                </ul>
              </div>
            </div>
            <h3 id="core-fellowship-experience">Core Fellowship Experience</h3>
            <p>There are two activities required of all Interact Fellows:</p>
            <ol type="1">
              <li>
                <p>
                  <strong>Fellowship Retreat</strong>: The core of the Interact
                  Fellowship experience is our annual retreats. As a Fellow, you
                  are expected to attend two in-person retreats, which are paid
                  for by Interact within your first three years as a Fellow.
                </p>
              </li>
              <li>
                <p>
                  <strong>Course</strong>: Starting in 2021, all Fellows
                  participate in the Core Course. Though it’s called a “course”,
                  it’s more of a guided discussion group on topics related to
                  the history, ethics, and philosophy of technology.
                </p>
              </li>
            </ol>
            <h4 id="fellowship-retreat">Fellowship Retreat</h4>
            <p>
              Each Interact Fellow is attends two retreats: the first is with
              the Fellows from the previous year, and the second is with the
              Fellows from the year below. We typically host one retreat each
              summer in Northern California.
            </p>
            <p>
              In order to become a permanent member of the larger Interact
              community, Fellows are required to attend two Fellowship retreats.{" "}
              <strong>
                The two retreats must be completed within 3 years of being
                accepted.
              </strong>{" "}
              Individuals who are accepted but unable to attend the Fellowship
              Retreat will be required to reapply the following year, with
              exceptions made on a case-by-case basis for medical or other
              emergencies.
            </p>
            <p>
              The Retreat is mostly unstructured, and is an opportunity to
              foster relationships with other Interact Fellows. Fellowship
              Retreats typically happen on a Thursday - Sunday and are based in
              Northern California, so often involve hiking, swimming, and
              campfires. Meals, lodging, and local transportation at the
              Fellowship Retreat are paid for by Interact, with subsidies
              available for travel to and from Northern California.
            </p>
            <h4 id="core-course">Core Course</h4>
            <p>
              Starting in 2021, all Fellows participate in the Core Course. This
              course is a discussion group on the history, philosophy, and
              ethics of technology. We will ask questions like: “what does it
              mean to be a technologist?“, “What are the historical and
              ideological origins of Silicon Valley?“, and “How should we
              understand the role that technology plays in the course of
              history?”
            </p>
            <h3 id="programs">Programs </h3>
            <h4 id="circles">Circles </h4>
            <p>
              A Circle is a small group of Interacters who work on a shared
              project over the course of a term. Each term, we send out a call
              for Circles topics, and anyone in Interact can lead a Circle on
              any topic of their choosing. This lead is called the “Center.”
            </p>
            <p>
              <strong>Who can participate:</strong> Anyone in Interact can
              Center a Circle, and anyone can apply to participate in any
              Circle.
            </p>
            <p>
              <strong>What is involved:</strong> Working on a shared creative or
              intellectual project over the course of a term. Past Circles have
              explored a huge diversity of topics, including:
            </p>
            <ul>
              <li>
                <p>The history &amp; ethics of technology</p>
              </li>
              <li>
                <p>Death &amp; dying</p>
              </li>
              <li>
                <p>Reimagining the future of housing</p>
              </li>
              <li>
                <p>Creating a climate-conscious art studio</p>
              </li>
              <li>
                <p>Investigating crypto’s future in Interact</p>
              </li>
            </ul>
            <p>
              At the end of each Circles session we hold a symposium where the
              Circles can share their work with the Interact community.
            </p>
            <p>
              <strong>When does it take place:</strong> Spring, Summer, Fall
              term
            </p>
            <p>
              <strong>Where can you learn more:</strong>{" "}
              <a href="http://circles.joininteract.com/">Circles FAQ</a>
            </p>
            <h4 id="founder-focus-group">Founder Focus Group</h4>
            <p>
              Founder Focus Group helps early stage founders leverage the
              knowledge and expertise of the Interact community to support their
              company’s early trajectory. It encourages reflection, rigor and
              accountability amongst talented peers at similar stages in the
              company-building lifecycle.
            </p>
            <p>
              <strong>Who can participate:</strong> Interacters at the early
              stages of starting their companies in any industry. One co-founder
              must be in Interact and have serious intent (i.e. is already
              working full-time on the company or intends to be working
              full-time on the company within the next 3-6 months if things work
              out).
            </p>
            <p>
              <strong>What is involved:</strong>
            </p>
            <ul>
              <li>
                <p>
                  Weekly focus groups with other founding teams focused on
                  learnings and progress, moderated by FFG partners
                </p>
              </li>
              <li>
                <p>
                  Bi-monthly speaker sessions with venture partners and later
                  stage Interact founders
                </p>
              </li>
              <li>
                <p>
                  Bi-monthly social hours to build relationships with other
                  founders in the Interact community
                </p>
              </li>
              <li>
                <p>
                  Opt-in office hours with FFG partners (Interact founders
                  who’ve built valuable companies)
                </p>
              </li>
              <li>
                <p>
                  1:1 support with your fundraising strategy and thinking
                  through company milestones
                </p>
              </li>
            </ul>
            <p>
              <strong>When does it take place:</strong> New founders can join
              each term
            </p>
            <p>
              <strong>Where can you learn more:</strong>{" "}
              <a href="https://joininteract.notion.site/Founder-Focus-Group-FFG-b85d29b498df4982971ea565b5106932">
                FFG Overview
              </a>
            </p>
            <h4 id="office-hours">Office Hours </h4>
            <p>
              Office Hours are an opportunity to meet with Interact’s venture
              sponsors, who can support you in starting or scaling a new
              venture. These meetings are for you to build personal
              relationships through informal conversation; they are not pitch
              meetings.
            </p>
            <p>
              <strong>Who can participate:</strong> Interacters who are
              currently founders, or are imminently starting a company.
            </p>
            <p>
              <strong>What is involved:</strong> Founders can sign up to be
              matched with our venture partners according to (1) the partner’s
              expertise and industries of interest and (2) the founders’ request
              for support with specific problems. Interact will set up a 30
              minute meeting between the venture partner and the Interacter and
              provide support to the Interacter before the call.
            </p>
            <p>
              <strong>When does it take place:</strong> Spring, Summer, Fall
              term
            </p>
            <p>
              <strong>Where can you learn more:</strong>{" "}
              <a href="https://docs.google.com/document/d/1okxy5YRLKItZ6Kbe3d5l06wGojB3v9FvrVxfAPVWM0A/edit">
                Interact Partner Office Hours Overview
              </a>
            </p>
            <h3 id="community-gatherings">Community Gatherings</h3>
            <h4 id="community-retreat">Community Retreat</h4>
            <p>
              The Community Retreat happens once a year, and is open to all
              members of the Interact community who have attended two Fellowship
              Retreats. The Community Retreat is a time to reconnect with the
              Interact Community at large. The format is very similar to that of
              the Fellowship Retreats and is typically hosted in Northern
              California. The Community Retreat cost is not covered by Interact,
              but we do have subsidies available if needed.
            </p>
            <h4 id="in-person-city-gatherings">In-Person City Gatherings</h4>
            <p>
              Interacters can be found in nearly every major city on earth, and
              community members regularly organize informal meetups in their
              hometowns. In particular, there are small-group city gatherings
              planned in San Francisco, New York City, Boston, Los Angeles,
              London, Taipei, Toronto, Chicago, and Washington DC. Make sure to
              keep your location up to date in the{" "}
              <a href="http://members.joininteract.com">Member Portal</a> in
              order to be invited to the in-person gatherings in your city. You
              can also subscribe to get updates about city-wide events{" "}
              <a href="https://go.joininteract.com/list-serves">here</a>.
            </p>
            <p>
              If you’re interested in hosting your own event, we have resources
              to help you host either in-person or remotely. If you have an idea
              for an in-person or remote event, please{" "}
              <a href="mailto:santi@joininteract.com">reach out to Santi</a> or
              simply post about it in Slack.
            </p>
            <h3 id="communication-engagement-tools">
              Communication &amp; Engagement Tools{" "}
            </h3>
            <h4 id="member-portal">Member Portal</h4>
            <p>
              The private{" "}
              <a href="https://members.joininteract.com/">
                Interact Member Portal
              </a>{" "}
              is a resource hub built by and for the Interact community.
            </p>
            <p>In the Member Portal, you can:</p>
            <ul>
              <li>
                <p>
                  Edit your Interact member profile with a host of new fields
                  (profile picture, name, pronouns, name pronunciation, company
                  and role, location, etc)
                </p>
              </li>
              <li>
                <p>
                  Share your expertise and connect with other Interacters that
                  share your interests
                </p>
              </li>
              <li>
                <p>
                  Check out Community Updates and add collaborations with other
                  Interacters
                </p>
              </li>
              <li>
                <p>View and contact our Venture Partners</p>
              </li>
            </ul>
            <p>
              You can see a detailed explanation of the Member Portal, along
              with usage guidelines{" "}
              <a href="https://docs.google.com/document/d/18JQrfFXSVnKIcN132bIDFEYdJpBnVTxUymQeHwfM2os/edit#">
                here
              </a>
              .
            </p>

            <h4 id="community-newsletter">Community Newsletter</h4>
            <p>
              Every term, we send out a Community Newsletter that includes
              updates from the community. Seeing Interacters' life updates is
              always a highlight of the year. You will be sent an email from the
              Interact team asking for you to complete your community update
              towards the end of each term. You can see past Newsletters in the
              “Newsletters” section of the Member Portal{" "}
              <a href="https://members.joininteract.com/home">here</a>.
            </p>
            <h4 id="interact-calendar">Interact Calendar</h4>
            <p>
              We have a shared calendar that you can subscribe to in order to
              see all of the Interact events. We post events like Town Halls,
              Clubhouse Discussions, Retreat dates, in-person city meet ups and
              more. Make sure to use{" "}
              <a href="https://calendar.google.com/calendar/u/7?cid=ZXZlbnRzQGpvaW5pbnRlcmFjdC5jb20">
                this link to subscribe
              </a>{" "}
              to the Interact Events calendar!
            </p>
            <h4 id="townhalls">Townhalls</h4>
            <p>
              We periodically host Townhalls, where we give an update on all
              Interact programming, and provide a forum where you can ask your
              questions about Interact that aren’t already answered by this
              guide.
            </p>
          </div>
        </section>
        <section id="contact">
          <div className="content">
            <h2 id="contact-the-interact-team">Contact the Interact Team </h2>
            <p>
              The Interact Team is always available to chat, and we want to hear
              from you! Whether you have a logistical question, a vague concern,
              or are interested in volunteering, our door is always open. Here
              is our current full-time and volunteer team:
            </p>
            <TeamList />
            <p>
              If you have a question or concern but don’t know who to send it
              to, email{" "}
              <a href="mailto:team@joininteract.com">team@joininteract.com</a>{" "}
              and we will route you to the right person.
            </p>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default GuidePage;

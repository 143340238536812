import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import LogRocket from "logrocket";

import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import ProfilePage from "./pages/ProfilePage";
import GuidePage from "./pages/GuidePage";
import RefreshPage from "./pages/RefreshPage";
import RedirectPage from "./pages/RedirectPage";
import NotFoundPage from "./pages/NotFoundPage";
import MemberHome from "./pages/MemberHome";
import "moment-timezone";

import "./App.scss";

const enhance = compose(
  withRouter,
  connect(({ firebase: { auth }, user: { isWhitelisted, email } }) => ({
    auth,
    isWhitelisted,
    whitelistEmail: email,
  }))
);

class App extends Component {
  render() {
    const { auth, isWhitelisted, whitelistEmail, location } = this.props;
    const params = new URLSearchParams(location.search);
    // Don't parse params directly becuase redirectTo could have ampersands.
    const redirectTo = params.has("redirectTo")
      ? location.search.split("?redirectTo=").pop()
      : null;
    let content;
    if (!isLoaded(auth)) {
      content = <div className="root-loading"></div>;
    } else if (
      isEmpty(auth) ||
      !isWhitelisted ||
      auth.email !== whitelistEmail
    ) {
      content = <LoginPage redirectTo={location.pathname + location.search} />;
    } else if (redirectTo) {
      content = <Redirect to={redirectTo} />;
    } else {
      // Note: This is Google Auth's name + email, not Airtable's.
      LogRocket.identify(auth.uid, {
        name: auth.displayName,
        email: auth.email,
      });
      content = (
        <React.Fragment>
          <Route component={RefreshPage} exact path="/refresh" />
          <Route component={RedirectPage} exact path="/url" />
          <Route component={RedirectPage} exact path="/discord" />
          <Switch>
            <Route component={ProfilePage} exact path="/profile" />
            <Route component={GuidePage} exact path="/guide" />
            <Route component={MemberHome} exact path="/partner/:partnerSlug" />
            <Route component={MemberHome} exact path="/member/:memberSlug" />
            <Route component={MemberHome} exact path="/url" />
            <Route component={MemberHome} exact path="/discord" />
            <Route component={MemberHome} exact path="/refresh" />
            <Route component={MemberHome} exact path="/home" />
            <Route component={MemberHome} exact path="/" />
            <Route component={NotFoundPage} path="*" />
          </Switch>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Route component={LogoutPage} exact path="/logout" />
        {content}
      </React.Fragment>
    );
  }
}

export default enhance(App);

export const CLICK_CTA = 'Click CTA';
export const CLICK_ENTRY = 'Click Entry';
export const CLICK_CONTACT_INFO = 'Click Contact Info';
export const EXPAND_SECTION = 'Expand Section';
export const Sections = {
  MEMBERS: 'Members',
  JOBS: 'Jobs',
  EVENTS: 'Events',
  RESOURCES: 'Resources',
  NEWSLETTERS: 'Newsletters',
  TEAM: 'Team',
  VENTURE: 'Venture',
}

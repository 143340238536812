import * as ActionTypes from '../constants/ActionTypes';

export const initializeProject = (fields) => ({
  type: ActionTypes.PROJECT_INITIALIZE_PROJECT,
  fields,
});

export const setProjectField = (fieldName, value) => ({
  type: ActionTypes.PROJECT_SET_FIELD,
  fieldName,
  value,
});

export const setProjectRequestState = (requestState, error) => ({
  type: ActionTypes.PROJECT_SET_REQUEST_STATE,
  requestState,
  error
});

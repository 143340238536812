import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { firebaseConnect } from "react-redux-firebase";
import { connectHits, connectSearchBox } from "react-instantsearch-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { parsePhoneNumberFromString } from "libphonenumber-js/max";
import { FaEnvelope, FaTwitter, FaFacebook, FaLinkedin } from "react-icons/fa";
import {
  CLICK_CTA,
  CLICK_ENTRY,
  EXPAND_SECTION,
  Sections,
} from "../constants/Analytics";
import FilterableTable from "./FilterableTable";
import TableColumn from "./FilterableTable/TableColumn";
import { TABLES } from "../shared/schema";
import ProfileOnboardingJourney, {
  getOnboardingStatus,
} from "../components/ProfileOnboardingJourney";
import { withCurrentMember } from "../utils";
import Button from "../components/Button";
import Chip from "../components/Chip";

import "./MemberList.scss";
import Map from "./Map";
const googleApiKeyMap = "AIzaSyAuNcdHthTWG3SYhZvzrtk2nhNfvugccAc";

const enhance = compose(
  withRouter,
  connectHits,
  connectSearchBox,
  withCurrentMember,
  firebaseConnect((props) => [{ path: TABLES.MEMBERS.firebaseRef }]),
  connect(({ firebase }) => ({
    members: firebase.data[TABLES.MEMBERS.firebaseRef],
  }))
);

class MemberList extends Component {
  constructor(props) {
    super(props);
    this.getOnClickRow = this.getOnClickRow.bind(this);
  }

  getOnClickRow(row) {
    const { history } = this.props;
    return (e) => {
      window.analytics.track(CLICK_ENTRY, {
        section: Sections.MEMBERS,
        item: row,
      });
      history.push(`/member/${row.slug}`);
    };
  }

  render() {
    const { hits, currentRefinement, refine, currentMember } = this.props;
    const members = Object.values(this.props.members || {});

    const onMarkerClustererClick = async (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers();
      const city = clickedMarkers[0].title; // hack: store city shorthand in title parameter of Marker component
      refine(city);
    };

    if (!currentMember) {
      return (
        <div className="placeholder">
          <span className="Spinner dark" />
        </div>
      );
    }

    const { segmentsComplete, totalSegments } =
      getOnboardingStatus(currentMember);
    const profileComplete = segmentsComplete === totalSegments;
    return (
      <div className={`searchable-table ${profileComplete ? "" : "disabled"}`}>
        {!profileComplete ? (
          <React.Fragment>
            <div className="scrim" />
            <div className="warning">
              <p>You must complete your profile to search and view members</p>
              <ProfileOnboardingJourney condensed={true} />
              <Button
                to="/profile"
                onClick={() =>
                  window.analytics.track(CLICK_CTA, {
                    section: Sections.MEMBERS,
                    buttonText: "Complete Profile",
                  })
                }
                color="green"
              >
                Complete Profile
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <span />
        )}
        <Map
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKeyMap}&v=3.exp&libraries=geometry,drawing,places`}
          // googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          members={normalizeMembersList(members)}
          onMarkerClustererClick={onMarkerClustererClick}
        />
        <FilterableTable
          rows={normalizeMembersList(members)}
          limit={10}
          searchEnabled={true}
          currentRefinement={currentRefinement}
          getOnClickRow={this.getOnClickRow}
          hits={hits}
          onShowAll={() => {
            window.analytics.track(EXPAND_SECTION, {
              section: Sections.MEMBERS,
            });
          }}
        >
          <TableColumn
            cid="name"
            title="Member"
            getContent={(m) => (
              <React.Fragment>
                {renderHeadshot(m)}
                <span className="name-and-role">
                  <span className="m-name">{m.fullName}</span>
                  <span className="m-role">{m.current}</span>
                </span>
              </React.Fragment>
            )}
          />
          <TableColumn
            cid="location"
            title="Location"
            isFilterable={true}
            filterOptionsSortingMethod="count"
            getContent={(m) => (
              <React.Fragment>
                {m.location ? m.location.join(", ") : <span className="none" />}
              </React.Fragment>
            )}
          />
          <TableColumn
            cid="interests"
            title="Interests"
            isFilterable={true}
            filterOptionsSortingMethod="ascending"
            getContent={(m) =>
              m.interests && m.interests.length > 0 ? (
                <React.Fragment>
                  <ul className="chips">
                    {m.interests.map((interest, idx) =>
                      idx % 2 === 0 ? (
                        <Chip size="small" key={idx}>
                          {interest}
                        </Chip>
                      ) : null
                    )}
                  </ul>
                  <ul className="chips">
                    {m.interests.map((interest, idx) =>
                      idx % 2 === 1 ? (
                        <Chip size="small" key={idx}>
                          {interest}
                        </Chip>
                      ) : null
                    )}
                  </ul>
                </React.Fragment>
              ) : (
                <span className="none" />
              )
            }
          />
          <TableColumn
            cid="skills"
            title="Skills"
            isFilterable={true}
            filterOptionsSortingMethod="ascending"
            getContent={(m) =>
              m.skills && m.skills.length > 0 ? (
                <React.Fragment>
                  <ul className="chips">
                    {m.skills.map((skill, idx) =>
                      idx % 2 === 0 ? (
                        <Chip size="small" key={idx}>
                          {skill}
                        </Chip>
                      ) : null
                    )}
                  </ul>
                  <ul className="chips">
                    {m.skills.map((skill, idx) =>
                      idx % 2 === 1 ? (
                        <Chip size="small" key={idx}>
                          {skill}
                        </Chip>
                      ) : null
                    )}
                  </ul>
                </React.Fragment>
              ) : (
                <span className="none" />
              )
            }
          />
          <TableColumn
            cid="cohort"
            title="Cohort"
            isFilterable={true}
            filterOptionsSortingMethod="descending"
          />
          <TableColumn
            cid="links"
            title="Links"
            getContent={(m) => renderLinks(m, Sections.MEMBERS)}
          />
        </FilterableTable>
      </div>
    );
  }
}

function getPhotoURL(url) {
  if (!url) {
    return null;
  }

  if (url.match(/.*dropbox.*/)) {
    let u = new URL(url);
    u.searchParams.set("raw", 1);
    return u.toString();
  }

  if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
    return url;
  }

  if (url.match(/^https:\/\/firebasestorage.googleapis.com/)) {
    return url;
  }

  return null;
}

export function renderHeadshot(m) {
  const headshotURL = getPhotoURL(m.linkToHeadshot);
  if (!m.fullName) {
    console.log(m);
  }
  return headshotURL ? (
    <i
      key={m.airtableId}
      style={{ backgroundImage: "url(" + headshotURL + ")" }}
    />
  ) : (
    <span className="headshot-placeholder">{m.fullName[0]}</span>
  );
}

export function renderLinks(m, section) {
  const Link = ({ url, children, onClick }) => {
    return typeof url === "string" ? (
      <a onClick={onClick} href={url} rel="noopener noreferrer" target="_blank">
        {children}
      </a>
    ) : (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a>&nbsp;</a>
    );
  };

  const trackClick = function (method) {
    return () => {
      window.analytics.track(CLICK_ENTRY, {
        section: section,
        item: {
          user: m,
          method: method,
        },
      });
    };
  };

  return (
    <div className="link-grid">
      <Link onClick={trackClick("Email")} url={m.mailtoUrl}>
        <FaEnvelope />
      </Link>
      <Link onClick={trackClick("Twitter")} url={m.twitterUrl}>
        <FaTwitter />
      </Link>
      <Link onClick={trackClick("Facebook")} url={m.facebookProfileUrl}>
        <FaFacebook />
      </Link>
      <Link onClick={trackClick("Linkedin")} url={m.linkedinProfileUrl}>
        <FaLinkedin />
      </Link>
    </div>
  );
}

export function normalizeMembersList(members) {
  if (!Array.isArray(members) || members.length === 0) {
    return [];
  }

  const normalized = members
    .filter((m) => {
      return (
        (m.firstName && m.firstName.trim() !== "") ||
        (m.lastName && m.lastName.trim() !== "") ||
        (m.fullName && m.fullName.trim() !== "")
      );
    })
    .map((m) => {
      const fullName =
        m.fullName || `${m.firstName.trim()} ${m.lastName.trim()}`;
      const phoneNumber =
        m.phoneNumber &&
        parsePhoneNumberFromString(m.phoneNumber.toString(), "US");
      return {
        key: m.emailAddress,
        emailAddress: m.emailAddress,
        fullName,
        airtableId: m.airtableId,
        cohort: m.cohort,
        slug: m.slug,
        skills: m.skills,
        interests: m.interests,
        linkToHeadshot: m.linkToHeadshot,
        location: m.currentLocation,
        geoLocation: m.geoLocation,
        current: normalizeCell(m.currentCompanyAndRole),
        role: normalizeCell(m.role || m.currentCompanyAndRole),
        mailtoUrl: `mailto:${fullName} <${m.emailAddress}>`,
        twitterUrl:
          m.twitterUsername && `https://twitter.com/${m.twitterUsername}`,
        phoneNumberUrl: phoneNumber && phoneNumber.getURI(),
        facebookProfileUrl: m.facebookProfileUrl,
        linkedinProfileUrl: m.linkedinProfileUrl,
        team: m.team,
        introLink: m.introLink,
      };
    });
  normalized.sort((a, b) => a.fullName.localeCompare(b.fullName));

  return normalized;
}

function normalizeCell(_cell) {
  if (_cell && _cell.constructor === Array) {
    return _cell.join(", ");
  }

  if (typeof _cell !== "string") return null;

  const cell = _cell.trim();
  if (cell === "") return null;
  if (cell.toLowerCase() === "n/a") return null;

  return cell;
}

export default enhance(MemberList);

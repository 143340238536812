import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from 'redux';
import { ModalType } from '../../constants/enums';
import * as ModalActions from "../../actions/ModalActions";

import PartnerModal from './PartnerModal';
import MemberModal from './MemberModal';
import EditProjectModal from './EditProjectModal';

const MODALS = {
  [ModalType.PARTNER_MODAL]: PartnerModal,
  [ModalType.MEMBER_MODAL]: MemberModal,
  [ModalType.EDIT_PROJECT_MODAL]: EditProjectModal,
};

const enhance = compose(
  withRouter,
  connect(
    ({ modal }) => ({
      currentModal: modal
    }),
    (dispatch, ownProps) => ({
      onClose: () => {
        dispatch(ModalActions.closeModal());
      },
    })
  )
);

export class ModalManager extends Component {
  constructor() {
    super();
    this.onKeyup = this.onKeyup.bind(this);
    this.root = null;
    this.startClosing = this.startClosing.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyup);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyup);
    document.body.classList.remove('modal-open');
    this.props.onClose()
  }

  onKeyup(e) {
    switch(e.keyCode) {
      case (27): // Escape
        this.startClosing();
        break;
      default:
        break;
    }
  }

  close() {
    const { currentModal, onClose, history } = this.props;
    if (!currentModal) {
      return;
    }
    this.root.classList.remove('closing');
    if (currentModal.context?.returnTo) {
      history.push(currentModal.context.returnTo);
    }
    onClose();
  }

  startClosing(e) {
    this.root.classList.add('closing');
    setTimeout(this.close, 300);
  }

  render() {
    const {currentModal} = this.props;

    let renderedModal;

    if (currentModal?.modalType) {
      const ModalComponent = MODALS[currentModal.modalType];
      document.body.classList.add('modal-open');

      renderedModal = <ModalComponent {...currentModal.modalProps} startClosing={this.startClosing} />;
    } else {
      document.body.classList.remove('modal-open');
    }

    return <div ref={div => this.root = div} className="Modal">{renderedModal}</div>
  }
}

export default enhance(ModalManager);

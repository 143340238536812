import React, { Component } from "react";

import "./RefreshBar.scss";

class RedirectBar extends Component {
  render() {
    const { redirecting } = this.props;

    return (
      <div className={`RefreshBar ${redirecting ? "refreshing" : ""}`}>
        <div className="Spinner" /> Redirecting you...
      </div>
    );
  }
}

export default RedirectBar;

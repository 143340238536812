import React, { PureComponent } from 'react';
import classNames from 'classnames';
import FilterDropdown from '../../FilterDropdown';
import { FaFilter } from 'react-icons/fa';

class TableColumn extends PureComponent {

  render() {
    const {
      activeFilters,
      cid,
      clearFilter,
      filterOptions,
      getContent,
      hideFilterControls,
      isFilterable,
      isFilterControlsShown,
      mode,
      row,
      title,
      toggleFilterControls,
      toggleFilterOption,
    } = this.props;
    const cn = classNames(
      cid,
      'table-cell',
      {'body-cell': (mode === 'body')},
      {'is-active': !!activeFilters},
      {'header-cell': (mode === 'header')},
      {'filterable': (mode === 'header' && isFilterable)},
    );

    if (mode === 'body') {
      const content = getContent ? getContent(row) : row[cid] || <span className="none" />

      return (
        <div className={cn}>
          {content}
        </div>
      );

    } else if (mode === 'header') {
      return (
        <div className={cn}
             onClick={toggleFilterControls}
             ref={t => this.toggleButton = t}>
          <span className="label">{title}</span>
          {
            isFilterable && (
              <React.Fragment>
                <span className="filter-icon">
                  <FaFilter size='10px'/>
                </span>
                <FilterDropdown
                  options={filterOptions}
                  isOpen={isFilterControlsShown}
                  activeFilters={activeFilters}
                  toggleFilterOption={toggleFilterOption}
                  clearFilter={clearFilter}
                  closeDropdown={hideFilterControls}
                  toggleButton={this.toggleButton}
                  />
              </React.Fragment>
            )
          }
        </div>
      );

    } else {
      return <span />;
    }
  }
}

export default TableColumn;

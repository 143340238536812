export const USER_UPDATE_IS_WHITELISTED = 'USER_UPDATE_IS_WHITELISTED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const PROFILE_SET_FIELD = 'PROFILE_SET_FIELD';
export const PROFILE_INITIALIZE_PROFILE = 'PROFILE_INITIALIZE_PROFILE';
export const PROFILE_SET_REQUEST_STATE = 'PROFILE_SET_REQUEST_STATE';
export const PROJECT_SET_FIELD = 'PROJECT_SET_FIELD';
export const PROJECT_INITIALIZE_PROJECT = 'PROJECT_INITIALIZE_PROJECT';
export const PROJECT_SET_REQUEST_STATE = 'PROJECT_SET_REQUEST_STATE';

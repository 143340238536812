import { withCurrentMember } from "../utils";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

const enhance = compose(withRouter, withCurrentMember);

const DISCORD_URL = "https://discord.gg/3fnStPBy";

class RefreshPage extends Component {
  componentDidUpdate() {
    const { location, currentMember } = this.props;
    if (location.pathname === "/discord") {
      window.location.href = DISCORD_URL;
    }
    // Don't parse URL params because the URL can have ampersands in it!
    const url = decodeURIComponent(location.search.split("?u=").pop());
    if (url && url.indexOf("[[member_email]]") !== -1) {
      if (currentMember) {
        const newUrl = url
          .replace(
            "[[member_email]]",
            currentMember.emailAddress ||
              "Oops! Something went wrong. Put your email here. Thanks!"
          )
          .replace(
            "[[full_name]]",
            currentMember.fullName ||
              "Oops! Something went wrong. Put your full name here. Thanks!"
          )
          .replace(
            "[[cohort]]",
            currentMember.cohort ||
              "Oops! Something went wrong. Put your Interact year here. Thanks!"
          );
        window.location.href = newUrl;
      }
    }
  }
  render() {
    return null;
  }
}

export default enhance(RefreshPage);

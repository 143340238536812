import React, { Component } from 'react';
import './index.scss';
import classNames from 'classnames';

class FilterDropdown extends Component {
  componentDidMount() {
    document.addEventListener('mouseup', this.onClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onClickOutside, false);
  }

  onClickOutside = (e) => {
    const { closeDropdown, toggleButton, isOpen } = this.props;

    if (!isOpen ||this.node.contains(e.target) || toggleButton.contains(e.target)) {
      return; // click is inside this component
    }

    closeDropdown();

    return;
  }

  render() {
    const {
      options,
      isOpen,
      activeFilters,
      toggleFilterOption,
      clearFilter,
    } = this.props;

    const className = classNames({
      "FilterDropdown": true,
      "is-open": isOpen,
    });

    return (
      <div className={className} ref={node => this.node = node}>
        <ul className="options">
        {
          options && options.map(([value, count]) => (
            <li
              key={value}
              onClick={toggleFilterOption(value)}
              className={classNames({
                "option": true,
                "is-active": !activeFilters || activeFilters[value],
              })}
            >
              {value} ({count})
            </li>
          ))
        }
        </ul>
        <div className="bottom-bar">
          <h6 onClick={clearFilter} className="clear">Clear</h6>
        </div>
      </div>
    );
  }
}


export default FilterDropdown;

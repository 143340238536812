import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './MemberChip.scss';

class MemberChip extends Component {
  render() {
    const {member, disabled} = this.props;

    if (disabled) {
      return (
        <span className="MemberChip">
          <i style={{backgroundImage: `url(${member.linkToHeadshot})`}} />
          {member.fullName}
        </span>
      );
    }

    return (
      <Link to={`/member/${member.slug}/`} className="MemberChip">
        <i style={{backgroundImage: `url(${member.linkToHeadshot})`}} />
        {member.fullName}
      </Link>
    )
  }
}

export default MemberChip;

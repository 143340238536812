import React, { Component } from "react";
import {
  FaPen,
  FaTwitter,
  FaLinkedin,
  FaFacebook,
  FaEnvelope,
  FaTimes,
  FaCommentDots,
  FaFileAlt,
} from "react-icons/fa";
import { firebaseConnect, isLoaded } from "react-redux-firebase";
import { connect } from "react-redux";
import { compose } from "redux";
import { TABLES } from "../../shared/schema";
import { CLICK_ENTRY, Sections } from "../../constants/Analytics";

import {
  normalizeMembersList,
  renderHeadshot,
} from "../../components/MemberList";
import Button from "../../components/Button";
import Chip from "../../components/Chip";
import MemberUpdateList from "../../components/MemberUpdateList";
import "./MemberModal.scss";
import "./Modal.scss";

const enhance = compose(
  connect(({ firebase }) => ({
    auth: firebase.auth,
  })),
  firebaseConnect((props) => [
    {
      path: TABLES.WHITELIST.firebaseRef,
      queryParams: ["orderByChild=emailAddress", `equalTo=${props.auth.email}`],
    },
    { path: TABLES.UPDATES.firebaseRef },
    { path: TABLES.MEMBERS.firebaseRef },
    { path: TABLES.TEAM.firebaseRef },
  ]),
  connect(({ firebase }) => ({
    whitelist: firebase.data[TABLES.WHITELIST.firebaseRef],
    updates: firebase.data[TABLES.UPDATES.firebaseRef],
    members: firebase.data[TABLES.MEMBERS.firebaseRef],
    team: firebase.data[TABLES.TEAM.firebaseRef],
  }))
);

const toOxfordCommaList = (items) => {
  if (items.length === 0) {
    return "";
  } else if (items.length === 1) {
    return items[0];
  } else if (items.length === 2) {
    return items.join(" and ");
  } else {
    return items
      .map((l, idx) => (idx === items.length - 1 ? `and ${l}` : `${l}, `))
      .join("");
  }
};

export class MemberModal extends Component {
  componentDidMount() {
    window.analytics.page("MemberModal");
    window.scrollTo({ top: 0 });
  }

  renderContactSection() {
    const { member } = this.props;

    const trackClick = (url) => (e) => {
      window.analytics.track(CLICK_ENTRY, {
        section: Sections.Venture,
        item: member,
        link: url,
      });
    };

    const buttons = (
      <div className="button-group wrap">
        {member.emailAddress && (
          <Button
            target="_blank"
            onClick={trackClick(`mailto:${member.emailAddress}`)}
            href={`mailto:${member.emailAddress}`}
            color="green-line small"
          >
            <FaEnvelope />
            <span className="social-text">Email</span>
          </Button>
        )}
        {member.linkedinProfileUrl && (
          <Button
            target="_blank"
            onClick={trackClick(member.linkedinProfileUrl)}
            href={member.linkedinProfileUrl}
            color="green-line small"
          >
            <FaLinkedin />
            <span className="social-text">LinkedIn</span>
          </Button>
        )}
        {member.facebookProfileUrl && (
          <Button
            target="_blank"
            onClick={trackClick(member.facebookProfileUrl)}
            href={member.facebookProfileUrl}
            color="green-line small"
          >
            <FaFacebook />
            <span className="social-text">Facebook</span>
          </Button>
        )}
        {member.twitterUsername && (
          <Button
            target="_blank"
            onClick={trackClick(
              `https://twitter.com/${member.twitterUsername}`
            )}
            href={`https://twitter.com/${member.twitterUsername}`}
            color="green-line small"
          >
            <FaTwitter />
            <span className="social-text">Twitter</span>
          </Button>
        )}
      </div>
    );

    if (member.contactPreferences?.length > 0) {
      return (
        <React.Fragment>
          {buttons}
          <p className="small contact-instruction">
            {member.firstName} is open to being contacted about{" "}
            {toOxfordCommaList(
              member.contactPreferences.map((s) => s.toLowerCase()).sort()
            )}
            .
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="button-group">
            <Button
              target="_blank"
              onClick={trackClick(
                `mailto:team@joininteract.com?subject=Warm intro to ${member.fullName}`
              )}
              href={`mailto:team@joininteract.com?subject=Warm intro to ${member.fullName}`}
              color="green-line small"
            >
              <FaEnvelope />
              <span className="social-text">Request Intro</span>
            </Button>
          </div>
          <p className="small contact-instruction">
            To contact {member.firstName}, ask the Interact team for a warm
            intro.
          </p>
        </React.Fragment>
      );
    }
  }

  renderContent(isSelf) {
    const { member, team } = this.props;
    const t = Object.values(team || {});
    let normalizedTeam = normalizeMembersList(t);
    const memberTeamInfo = normalizedTeam.filter(
      (t) => t.emailAddress === member.emailAddress
    );

    return (
      <React.Fragment>
        <div className="header">
          {renderHeadshot(member)}
          <div className="header-text">
            <h2>
              <span className="full-name">{member.fullName}</span>
              {member.namePronunciation && (
                <span className="name-pronunciation">
                  <FaCommentDots />
                  <span className="tooltip">
                    I pronounce my name <br className="m-only" /> &ldquo;
                    {member.namePronunciation}.&rdquo;
                  </span>
                </span>
              )}
              {member.pronouns && (
                <span className="pronouns">
                  <h5 className="light">{member.pronouns.join(", ")}</h5>
                </span>
              )}
              {isSelf && (
                <span className="self-notice">
                  <Button color="green-text hidden-text" to="/profile">
                    <FaPen /> <span>Edit</span>
                  </Button>
                </span>
              )}
            </h2>
            <h3 className="light">{member.currentCompanyAndRole}</h3>
            {memberTeamInfo.length > 0 &&
              memberTeamInfo[0].role &&
              memberTeamInfo[0].team && (
                <div className="team">
                  <h4>
                    <span className="light">
                      {memberTeamInfo[0].role.toUpperCase()}, INTERACT{" "}
                      {memberTeamInfo[0].team.toUpperCase()} TEAM
                    </span>
                    <span className="link-to-doc">
                      {memberTeamInfo[0].introLink && (
                        <Button href={memberTeamInfo[0].introLink}>
                          <FaFileAlt />
                        </Button>
                      )}
                    </span>
                  </h4>
                </div>
              )}
          </div>
        </div>
        {member.firstPersonIntro && (
          <p className="welcome-message">{member.firstPersonIntro}</p>
        )}
        <div className="content-area">
          <div className="primary-area">
            {(member.cohort || member.currentLocation) && (
              <div className="cohort-and-location">
                {member.currentLocation && (
                  <div>
                    <h3>Location</h3>
                    <p>{member.currentLocation.join(", ")}</p>
                  </div>
                )}
                {member.cohort && (
                  <div>
                    <h3>Cohort</h3>
                    <p>{member.cohort}</p>
                  </div>
                )}
              </div>
            )}
            {member.interests?.length > 0 && (
              <React.Fragment>
                <h3>Interests</h3>
                <ul className="interests">
                  {member.interests.map((interest, idx) => (
                    <Chip key={idx}>{interest}</Chip>
                  ))}
                </ul>
              </React.Fragment>
            )}
            {member.skills?.length > 0 && (
              <React.Fragment>
                <h3>Skills</h3>
                <ul className="interests">
                  {member.skills.map((skill, idx) => (
                    <Chip key={idx}>{skill}</Chip>
                  ))}
                </ul>
              </React.Fragment>
            )}
          </div>
          <div className="secondary-area">
            <h3>Contact</h3>
            {this.renderContactSection()}
          </div>
        </div>
        {member.communityUpdates && (
          <React.Fragment>
            <h3>Updates</h3>
            <MemberUpdateList member={member} />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { member, startClosing, whitelist } = this.props;
    let content;
    if (member && isLoaded(whitelist)) {
      const airtableId = Object.values(whitelist)[0].member[0];
      content = this.renderContent(airtableId === member.airtableId);
    } else {
      content = (
        <div className="placeholder">
          <div className="Spinner dark" />
        </div>
      );
    }
    return (
      <div className="Modal">
        <div className="modal-scrim" onClick={startClosing}></div>
        <div className="modal-window">
          <div className="top-bar">
            <FaTimes className="close-button" onClick={startClosing} />
          </div>
          <div className="modal-content">{content}</div>
        </div>
      </div>
    );
  }
}

export default enhance(MemberModal);
